import { Stonk } from "@/objects/Stonk";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { format, formatDistanceToNow } from "date-fns";
import { Badge } from "../ui/badge";
import { BadgeInfo } from "lucide-react";
import { EmptyState } from "../interface/EmptyState";

export const StonkTransactionHistory = ({ stonk }: { stonk: Stonk }) => {

    const rows = [];
    for (let record of stonk.purchaseRecord.sort((a, b) => b.time - a.time)) {
        rows.push(
            <TableRow key={record.time}>
                <TableCell>
                    <div className="flex flex-col">
                        <span>{formatDistanceToNow(record.time, { addSuffix: true })}</span>
                        <span className="text-black/30 dark:text-white/30 text-xs">{format(record.time, 'Pp')}</span>
                    </div>
                </TableCell>
                <TableCell>{record.qty > 0 ? <Badge className="bg-red-500 text-white hover:dark:text-black hover:text-white">Bought</Badge> : <Badge className="bg-green-500 text-white hover:dark:text-black hover:text-white">Sold</Badge>}</TableCell>
                <TableCell className="text-right">{Math.abs(record.qty)}</TableCell>
                <TableCell className="text-right">${record.price.toFixed(2)}</TableCell>
                <TableCell className="text-right">{record.remaining}</TableCell>
            </TableRow>
        )
    }

    if (stonk.purchaseRecord.length === 0) {
        return (<EmptyState icon={BadgeInfo} text="No transactions" wrap={false} />);
    } else {
        return (
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead>Time</TableHead>
                        <TableHead>Type</TableHead>
                        <TableHead className="text-right">Quantity</TableHead>
                        <TableHead className="text-right">Price</TableHead>
                        <TableHead className="text-right">Running Qty</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        );
    }
}