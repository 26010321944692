import { SelectItemText } from "@radix-ui/react-select";
import { Loader } from "../../interface/Loader";
import { FormControl, FormDescription, FormItem, FormLabel, FormMessage } from "../../ui/form";
import { Label } from "../../ui/label";
import { Select, SelectContent, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "../../ui/select";
import { HermodBuild, useHermodBuilds } from "./useHermodBuilds";
import { useHermodOverrides } from "./useHermodOverrides";
import { Button } from "../../ui/button";
import { useState } from "react";
import { GameController } from "@/objects/GameController";

export function BuildSelectorMenu() {

    const { isLoading, builds } = useHermodBuilds();
    const overrides = useHermodOverrides();

    const [selectedChannel, setSelectedChannel] = useState<string>();
    const [selectedVersion, setSelectedVersion] = useState<string>(GameController.gameVersion);

    if (isLoading || !builds) {
        return (<Loader />);
    } else {
        const channels = Object.keys(builds as object);
        const currentChannel = overrides.channel ?? channels[0];
        if (selectedChannel === undefined) {
            setSelectedChannel(currentChannel);
        }

        const channelObjects = channels.map(channel => {
            return (<SelectItem value={channel} key={channel}>{channel}</SelectItem>)
        });

        const startOverride = () => {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('hermod-override', `channel:${selectedChannel},version:${selectedVersion}`);
            window.location.href = window.location.origin + window.location.pathname + '?' + searchParams.toString() + window.location.hash;
        };

        const stopOverride = () => {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('hermod-override', `clear`);
            window.location.href = window.location.origin + window.location.pathname + '?' + searchParams.toString() + window.location.hash;
        };

        return (
            <div className="min-w-80 p-4 flex flex-col gap-4">
                <div>
                    <Label className="block mb-2">Channel</Label>
                    <Select onValueChange={e => setSelectedChannel(e)}>
                        <SelectTrigger>
                            <SelectValue placeholder={currentChannel} />
                        </SelectTrigger>
                        <SelectContent>
                            {channelObjects}
                        </SelectContent>
                    </Select>
                </div>
                <VersionSelectorMenu builds={builds[selectedChannel!]} onChange={setSelectedVersion} />
                <Button onClick={startOverride}>Override Build</Button>
                {Object.keys(overrides).length > 0 ? <Button onClick={stopOverride}>Clear Override</Button> : <></>}
            </div>
        );
    }
}

function VersionSelectorMenu({ builds, onChange }: { builds: HermodBuild[], onChange: (version: string) => void }) {
    const versionObjects = builds.sort((a, b) => a.buildNumber - b.buildNumber).map(build => {
        return (
            <SelectItem value={build.buildVersion} key={build.buildCommit}>
                {build.buildVersion} Build {build.buildNumber} ({build.buildCommit.substring(0, 8).toUpperCase()})
            </SelectItem>
        );
    });

    return (
        <div>
            <Label className="block mb-2">Versions</Label>
            <Select onValueChange={e => onChange(e)} disabled={versionObjects.length === 0}>
                <SelectTrigger>
                    <SelectValue placeholder={versionObjects.length > 0 ? GameController.gameVersion : "No Builds Available"} />
                </SelectTrigger>
                <SelectContent>
                    {versionObjects}
                </SelectContent>
            </Select>
        </div>
    );
}