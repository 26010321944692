import { ChevronDown, ChevronUp } from "lucide-react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { useState } from "react";

interface BuySellWidgetOpts {
    label: string;
    disabled: boolean;
    value?: number;
    max?: number;
    min?: number;
    onAction?: (value: number) => void;
}

export const BuySellWidget = ({ opts }: { opts: BuySellWidgetOpts }) => {
    const [fieldValue, setFieldValue] = useState(opts.value ?? 0);

    const handleChange = (e: number) => {
        if (opts.max !== undefined && e > opts.max) return;
        if (opts.min !== undefined && e < opts.min) return;
        setFieldValue(e)
    };

    const onAction = () => {
        opts.onAction?.(fieldValue);
        setFieldValue(0);
    }

    return (
        <div className="flex gap-4 select-none">
            <div className="basis-1/2 flex gap-0 border rounded-md">
                <Input
                    value={fieldValue}
                    type="number"
                    onChange={(e) => handleChange(parseInt(e.target.value))}
                    disabled={opts.disabled}
                    className="rounded-r-none no-arrows border-none"
                />
                <Button
                    disabled={opts.disabled || (opts.max !== undefined && fieldValue >= opts.max)}
                    variant={"ghost"}
                    className="px-1 rounded-none border-l"
                    onClick={() => handleChange(fieldValue + 1)}
                >
                    <ChevronUp />
                </Button>
                <Button
                    disabled={opts.disabled || (opts.min !== undefined && fieldValue <= opts.min)}
                    variant={"ghost"}
                    className="px-1 rounded-l-none border-l"
                    onClick={() => handleChange(fieldValue - 1)}
                >
                    <ChevronDown />
                </Button>
            </div>
            <Button
                disabled={opts.disabled}
                className="basis-1/2"
                variant={"outline"}
                onClick={onAction}
            >
                {opts.label}
            </Button>
        </div>
    );
}