import BigNumber from "bignumber.js";
import { NumericalRecord } from "../NumericalRecord";
import { Purchasable } from "../Purchasable";
import { Job } from "../Job";

export class PackageCourrier extends Job {
    public static instance: PackageCourrier;

    constructor() {
        super("package-courrier", 0, { name: 'Package Courrier', description: 'The amount hours a week you work at your delievery gig.' });
        this.cost = new BigNumber(15);
        this.iteratorValue = new BigNumber(25.00);
        this.setTargetRecord("money");
        NumericalRecord.all.set(this.id, this as PackageCourrier);
        this.inheritanceTree.push(super.constructor.name);
    }

    public static getInstance(): PackageCourrier {
        if (!PackageCourrier.instance || !NumericalRecord.all.has('package-courrier')) {
            if (!NumericalRecord.all.has('package-courrier')) {
                PackageCourrier.instance = new PackageCourrier();
            } else {
                PackageCourrier.instance = NumericalRecord.all.get('package-courrier') as PackageCourrier;
            }
        }

        return PackageCourrier.instance;
    }

    isDisabled(): string | undefined {
        return NumericalRecord.all.get('money').allTimeValue.lt(500) ? `This job unlocks once you've earned $500 in total. ($${NumericalRecord.all.get('money').allTimeValue.toFixed(0)}/$500)` : undefined;
    }
}