import { Area, AreaChart, CartesianGrid, Legend, Line, LineChart, XAxis, YAxis } from "recharts";
import { Card, CardContent } from "../../ui/card";
import { ChartContainer, ChartTooltip, ChartTooltipContent } from "../../ui/chart";
import { Stonk } from "@/objects/Stonk";
import { useEffect, useState } from "react";
import { Loader } from "../../interface/Loader";

export const StonkGraphCanvas = ({
    startTime = Date.now(),
    endTime = Date.now() + (1000 * 60 * 60 * 24 * 5),
    stonks
}: {
    startTime?: number,
    endTime?: number,
    stonks: Stonk[]
}) => {

    const [data, setData] = useState<any[] | undefined>();

    const length = endTime - startTime;
    const step = length / 200;
    const areaGraphs: any = [];
    const selectedStonks = new Map<string, Stonk>();
    const chartConfig: any = {};
    for (let stonk of stonks) {
        selectedStonks.set(stonk.ticker, stonk);
    }
    const tempStonks = Array.from(selectedStonks.values()).sort((a: Stonk, b: Stonk) => a.startingPrice < b.startingPrice ? 1 : -1);;
    for (let n = 0; n < tempStonks.length; n++) {
        areaGraphs.push({
            element: <Area key={tempStonks[n].ticker} dataKey={tempStonks[n].ticker} type="natural" fill={`url(#fillColor${n})`} fillOpacity={0.4} stroke={`var(--color-${tempStonks[n].ticker})`} />,
            grad: <linearGradient key={"fillColor" + n} id={"fillColor" + n} x1="0" y1="0" x2="0" y2="1">
                <stop
                    offset="5%"
                    stopColor={`var(--color-${tempStonks[n].ticker})`}
                    stopOpacity={0.8}
                />
                <stop
                    offset="95%"
                    stopColor={`var(--color-${tempStonks[n].ticker})`}
                    stopOpacity={0.1}
                />
            </linearGradient>,
            stonk: tempStonks[n]
        });
        chartConfig[tempStonks[n].ticker] = {
            label: tempStonks[n].name,
            color: `hsl(var(--chart-${(n % 5) + 1}))`,
        };
    }

    useEffect(() => {
        setTimeout(() => {
            (async () => {
                const tempData: any[] = [];
                for (let i = 0; i < (length / step); i++) {
                    const time = Date.now() + (i * step);
                    let frameData: any = {};
                    for (let n = 0; n < stonks.length; n++) {
                        frameData[stonks[n].ticker] = stonks[n].calculatePrice(time);
                    }
                    tempData.push({
                        time: time,
                        ...frameData
                    });
                }
                setData(tempData);
            })();
        }, 50);
    }, [startTime, endTime, stonks]);

    if (!data) {
        return <div className="flex h-full w-full items-center justify-center">
            <Loader size={48} className="opacity-25" />
        </div>;
    } else {
        return <ChartContainer
            config={chartConfig}
            className="basis-full"
        >
            <AreaChart
                accessibilityLayer
                data={data}
                margin={{
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                }}
            >
                <XAxis dataKey="time" hide />
                <YAxis domain={["dataMin - 5", "dataMax + 2"]} hide />
                <defs>
                    {areaGraphs.map((graph: { grad: any }) => graph.grad)}
                </defs>
                {areaGraphs.map((graph: { element: any, stonk: Stonk }) => graph.element)}
                <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent hideLabel />}
                    formatter={(value: number, name: string, props: any) => (
                        <div className="flex flex-col content-start min-w-[120px] items-center text-xs text-muted-foreground">
                            <div className="flex w-full">
                                {selectedStonks.get(name)?.name} ({name})&nbsp;$
                                <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                                    {value.toFixed(2)}
                                </div>
                            </div>
                            <div className="flex w-full">
                                {new Date(props.payload.time).toLocaleString()}
                            </div>
                        </div>
                    )}
                />
            </AreaChart>
        </ChartContainer>
    }
};