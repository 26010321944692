import { ShieldAlert } from "lucide-react";
import { useRouteError } from "react-router-dom";

export const ErrorPage = () => {
    const error: any = useRouteError();

    return (
        <div className="w-full h-full flex-1 flex flex-col gap-8 justify-center items-center">
            <ShieldAlert size={128} />
            <div className="flex flex-col items-center gap-2">
                <div className="text-2xl">Uh oh, something went wrong!</div>
                <div className="text-muted">{error.statusText || error.message}</div>
            </div>
        </div>
    );
};