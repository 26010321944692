import BigNumber from "bignumber.js";

function getLargeNumberMagnitudeName(num: number): string {
    if (num < 7) {
        return "";
    } else {
        const magnitude = (Math.ceil((num - 6) / 3)) - 1;
        const names = [
            "million",
            "billion",
            "trillion",
            "quadrillion",
            "quintillion",
            "sextillion",
            "septillion",
            "octillion",
            "nonillion",
            "decillion",
            "undecillion",
            "duodecillion",
            "tredecillion",
            "quattuordecillion",
            "quindecillion",
            "sexdecillion",
            "septdecillion",
            "octodecillion",
            "novemdecillion",
            "vigintillion",
            "unvigintillion",
            "duovigintillion",
            "trevigintillion",
            "quattuorvigintillion",
            "quinvigintillion",
            "sexvigintillion",
            "septvigintillion",
            "octovigintillion",
            "novemvigintillion",
            "trigintillion",
            "untrigintillion",
            "duotrigintillion",
            "googol",
            "tretrigintillion",
            "quattuortrigintillion",
            "quintrigintillion",
            "sextrigintillion",
            "septtrigintillion",
            "octotrigintillion",
            "novemtrigintillion",
            "quadragintillion",
            "unquadragintillion",
            "duoquadragintillion",
            "trequadragintillion",
            "quattuorquadragintillion",
            "quinquadragintillion",
            "sexquadragintillion",
            "septquadragintillion",
            "octoquadragintillion",
            "novemquadragintillion",
            "quinquagintillion",
            "unquinquagintillion",
            "duoquinquagintillion",
            "trequinquagintillion",
            "quattuorquinquagintillion",
            "quinquinquagintillion",
            "sexquinquagintillion",
            "septquinquagintillion",
            "octoquinquagintillion",
            "novemquinquagintillion",
            "sexagintillion",
            "unsexagintillion",
            "duosexagintillion",
            "tresexagintillion",
            "quattuorsexagintillion",
            "quinsexagintillion",
            "sexsexagintillion",
            "septsexagintillion",
            "octosexagintillion",
            "novemsexagintillion",
            "septuagintillion",
            "unseptuagintillion",
            "duoseptuagintillion",
            "treseptuagintillion",
            "quattuorseptuagintillion",
            "quinseptuagintillion",
            "sexseptuagintillion",
            "septseptuagintillion",
            "octoseptuagintillion",
            "novemseptuagintillion",
            "octogintillion",
            "unoctogintillion",
            "duooctogintillion",
            "treoctogintillion",
            "quattuoroctogintillion",
            "quinoctogintillion",
            "sexoctogintillion",
            "septoctogintillion",
            "octooctogintillion",
            "novemoctogintillion",
            "nonagintillion",
            "unnonagintillion",
            "duononagintillion",
            "trenonagintillion",
            "quattuornonagintillion",
            "quinnonagintillion",
            "sexnonagintillion",
            "septnonagintillion",
            "octononagintillion",
            "novemnonagintillion",
            "centillion"
        ];
        return capitalizeFirstLetter(names[magnitude]);
    }
}

function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatLargeNumber(num: string | number | BigNumber): string {
    if (typeof num === "string" || typeof num === "number") {
        num = new BigNumber(num);
    }
    if (num.lt(1000000)) {
        return new Intl.NumberFormat([], {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(
            parseFloat(num.toString()),
        )
    } else {
        if (num.toString().includes("e")) {
            const places = (num.e ?? 1);
            let magNum = (places % 3);
            return `${parseFloat(num.toString().split("e")[0]).toFixed(3)} ${getLargeNumberMagnitudeName(num.e ?? 1)}`;
        } else {
            const components = num.toString().split(".");
            const places = components[0].length;
            const placeAdjustement = ((places - 7) % 3) + 1;
            return `${num.dividedBy(10 ** (places - placeAdjustement)).toFixed(3)} ${getLargeNumberMagnitudeName(places)}`;
        }
        // 1,000,000,003
    }
}