import { useEffect, useState } from "react";
import { NumericalRecord } from "./NumericalRecord";
import { Job } from "./Job";

export function useJob(record: Job) {
    const [canPurchase, setCanPurchase] = useState(record.canPurchase());

    const updateHook = () => {
        setCanPurchase(record.canPurchase());
    };

    useEffect(() => {
        record.addHook(updateHook);
    }, []);
    NumericalRecord.get('money', 0).addHook(updateHook);

    return {
        canPurchase
    };
}