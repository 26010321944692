import { useEffect, useState } from "react";
import { NumericalRecord } from "./NumericalRecord";
import { GameController } from "./GameController";

export function useNumericalRecord(record: NumericalRecord, minimumInterval: number = 1000 / GameController.uiTickRate) {
    const [value, setValue] = useState(record.value.toString());
    const [delta, setDelta] = useState(record.getDelta().toString());
    const [allTimeValue, setAllTimeValue] = useState(record.allTimeValue.toString());

    const updateHook = (newValue: string) => {
        setValue(newValue);
        setDelta(record.getDelta().toString());
        setAllTimeValue(record.allTimeValue.toString());
    };

    useEffect(() => {
        record.addHook(updateHook, minimumInterval);
    }, []);

    return {
        value,
        delta,
        allTimeValue
    };
}