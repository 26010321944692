import { NumericalRecord } from "@/objects/NumericalRecord";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { useNumericalRecord } from "@/objects/useNumericalRecord";
import BigNumber from "bignumber.js";
import { formatLargeNumber } from "@/objects/Utils";
import { GameController } from "@/objects/GameController";
import { NumericalRecordCardChart } from "./NumericalRecordCardChart";

export function NumericalRecordCard({ record }: { record: string }) {
    let recordObj = NumericalRecord.get(record, 0);
    let { value, delta } = useNumericalRecord(recordObj);
    const valueBg = new BigNumber(value);
    const deltaBg = new BigNumber(delta);

    let formattedValue = formatLargeNumber(valueBg);
    let formattedDelta = formatLargeNumber(deltaBg.multipliedBy(GameController.tickRate));

    return (
        <Card className="basis-1/4">
            <CardHeader className="bg-transparent">
                <CardDescription>Account Balance (Realized)</CardDescription>
                <CardTitle>$ {formattedValue}</CardTitle>
                <CardDescription>$ {formattedDelta} Mps</CardDescription>
            </CardHeader>
            <CardContent className="p-0 min-h-40 w-full flex">
                <NumericalRecordCardChart record={NumericalRecord.get("money", 0)} />
            </CardContent>
        </Card>

    )
}