
export class PollingManager {

    public lastPoll: number = 0;
    public maxPerSecond: number;

    public constructor(maxPerSecond: number) {
        this.maxPerSecond = maxPerSecond;
    }

    public attemptRun(callback: () => void): void {
        if (Date.now() - this.lastPoll > (1000 / this.maxPerSecond)) {
            this.lastPoll = Date.now();
            callback();
        }
    }
}