import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useTheme } from "@/components/ThemeProvider";
import { Moon, Sun } from "lucide-react";

export function ModeToggle() {

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    size="icon"
                    className="ghost"
                >
                    <LightModeIcon />
                    <span className="sr-only">Toggle theme</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
                <LightModeDropdown />
            </DropdownMenuContent>
        </DropdownMenu>
    );
}

export function LightModeDropdown() {
    const { setTheme } = useTheme();

    return (
        <>
            <DropdownMenuItem onClick={() => setTheme("light")}>
                Light
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setTheme("dark")}>
                Dark
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => setTheme("system")}>
                System
            </DropdownMenuItem>
        </>
    );
}

export function LightModeIcon({ className }: { className?: string }) {
    const { theme } = useTheme();

    return (
        <span className={className}>
            {theme !== "dark" && <Sun className="h-full w-full" />}
            {theme == "dark" && <Moon className="h-full w-full" />}
        </span>
    )
}