import prand from "pure-rand";
import { Stonk } from "../Stonk";
import { perlin } from "./Perlin";
import { StonkIndustry } from "./StonkIndustry";

export type StonkLayer = (stonk: Stonk | StonkIndustry, value: number, time: number, influence: number) => number;

export interface StonkLayerAffector {
    calculateNewValue: StonkLayer;
}

export const inflationStonkLayer = (stonk: Stonk | StonkIndustry, value: number, time: number, influence: number): number => {
    const timeDelta = time - stonk.startingTime;
    const inflationRate = 0.02 / (365 * 24 * 60 * 60 * 1000);
    return (influence * inflationRate * value * timeDelta) + value;
}

export const noiseStonkLayer = (stonk: Stonk | StonkIndustry, value: number, time: number, influence: number): number => {
    const generator = perlin(stonk.rootSeed * 100)
    const timeDelta = (time - stonk.startingTime) / (1000 * 60 * 60);
    let noise = 0;
    noise += generator.sample2d(timeDelta * 100, stonk.rootSeed + 1) * 1;
    noise += generator.sample2d(timeDelta * 10, stonk.rootSeed + 500) * 1.5;
    noise += generator.sample2d(timeDelta, stonk.rootSeed + 1000) * 2;
    return (influence * noise) + value;
}

export const marketFluctuationStonkLayer = (stonk: Stonk | StonkIndustry, value: number, time: number, influence: number): number => {
    const generator = perlin(stonk.rootSeed * 100)
    const timeDelta = (time - stonk.startingTime) / (1000 * 60 * 60 * 24);
    let noise = 0;
    noise += generator.sample2d(timeDelta, 1) * 4;
    return (influence * noise) + value;
}