interface HermodOverrides {
    version?: string;
    channel?: string;
    build?: string;
}

export function useHermodOverrides() {
    const packedCookies = document.cookie.split("; ");
    const overrideObject: HermodOverrides = {};
    for (let packedCookie of packedCookies) {
        const cookie = packedCookie.split("=");
        if (cookie[0] === "hermod-overrides") {
            const allOverrides = decodeURIComponent(cookie[1]).split(",");
            for (let override of allOverrides) {
                const [key, value] = override.split(":");
                overrideObject[key as keyof HermodOverrides] = value;
            }
        }
    }
    return overrideObject;
}