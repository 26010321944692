import { FileDown, FileUp, RotateCcw, Save, Trash2 } from "lucide-react";
import { DropdownMenuItem, DropdownMenuPortal, DropdownMenuSeparator, DropdownMenuShortcut, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger } from "../ui/dropdown-menu";
import { GameController, SerializedGameSave } from "@/objects/GameController";
import { DownloadBlob, PromptUpload, DecodeData } from "@/objects/SaveController";

export const SaveFileMenu = () => {

    const loadSaveFile = async () => {
        const data = await PromptUpload();
        const decodedData: SerializedGameSave = DecodeData(data as Uint8Array);
        console.log(decodedData);
        GameController.getInstance().load(decodedData);
    }

    return (
        <DropdownMenuSub>
            <DropdownMenuSubTrigger>
                <Save className="mr-2 h-4 w-4" />
                <span>Game Saves</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
                <DropdownMenuSubContent className="w-56">
                    <DropdownMenuItem onClick={() => GameController.getInstance().save()}>
                        <Save className="mr-2 h-4 w-4" />
                        <span>Save Game</span>
                        <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={() => DownloadBlob(GameController.getInstance().getSaveData(), "SaveGame.stonks")}>
                        <FileDown className="mr-2 h-4 w-4" />
                        <span>Save to File</span>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => GameController.getInstance().load()}>
                        <RotateCcw className="mr-2 h-4 w-4" />
                        <span>Revert to Last Save</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={loadSaveFile}>
                        <FileUp className="mr-2 h-4 w-4" />
                        <span>Load Save File</span>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <DropdownMenuItem onClick={() => GameController.getInstance().clearSave()}>
                        <Trash2 className="mr-2 h-4 w-4" />
                        <span>Clear Save</span>
                    </DropdownMenuItem>
                </DropdownMenuSubContent>
            </DropdownMenuPortal>
        </DropdownMenuSub>
    );
}