import { Stonk } from "@/objects/Stonk";
import { Progress } from "../ui/progress";

const fromCamelCase = (str: string) => {
    const result = str.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export const StonkAssociationChart = ({ stonk }: { stonk: Stonk }) => {

    const associationElements = [];
    for (const [key, value] of Object.entries(stonk.associations)) {
        associationElements.push(<div className="flex gap-2 items-center" key={key}>
            <div className="text-l font-light w-[15rem] flex items-center">{fromCamelCase(key)}</div>
            <div className="flex flex-1 gap-4 items-center">
                <Progress value={parseFloat(value) * 100} />
                <div className="w-[2rem] text-right">{Math.round(parseFloat(value) * 100)}%</div>
            </div>
        </div>);
    }

    return <div className="w-full flex flex-col gap-2">
        {associationElements}
    </div>;
}