import BigNumber from "bignumber.js";
import { NumericalRecord } from "../NumericalRecord";
import { Purchasable } from "../Purchasable";
import { Job } from "../Job";

export class FastFood extends Job {
    public static instance: FastFood;
    public typeType: ThisType<any> = FastFood;

    constructor() {
        super("fast-food", 0, { name: 'Fast Food', description: 'The amount hours a week you work at your fast food gig.' });
        this.cost = new BigNumber(10);
        this.iteratorValue = new BigNumber(15);
        this.setTargetRecord("money");
        NumericalRecord.all.delete(this.id);
        NumericalRecord.all.set(this.id, this as FastFood);
        this.inheritanceTree.push(super.constructor.name);
        return this;
    }

    public static getInstance(): FastFood {
        if (!FastFood.instance || !NumericalRecord.all.has('fast-food')) {
            if (!NumericalRecord.all.has('fast-food')) {
                FastFood.instance = new FastFood();
            } else {
                FastFood.instance = NumericalRecord.all.get('fast-food') as FastFood;
            }
        }
        return FastFood.instance;
    }
}