import * as Icons from "lucide-react";
import React, { ReactNode, useMemo, useRef, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Button } from "./button";
import { ViewportList } from "react-viewport-list";
import { Search } from "lucide-react";
import { Input } from "./input";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip";
import Fuse from "fuse.js";

export type IconType = React.ForwardRefExoticComponent<Omit<Icons.LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>>;

export interface IconPickerIcon {
    name: string;
    prettyName: string;
    icon: ReactNode;
}

const camelToTitleCase = (str: string): string => {
    const result = str.replace(/([A-Z])/g, " $1").replace(/( [A-Z] )/g, "");
    return (result.charAt(0).toUpperCase() + result.slice(1)).trim();
}

const StandardIcons: IconPickerIcon[] = [];
for (let IconName of Object.keys(Icons)) {
    try {
        const Icon = Icons[IconName as keyof typeof Icons] as IconType;
        if (Icon.hasOwnProperty("render") && IconName.toLowerCase() !== "icon") {
            StandardIcons.push({
                name: IconName,
                prettyName: camelToTitleCase(IconName),
                icon: <Icon className="text-white" />
            });
        }
    } catch (e) {
        console.log(e);
    }
}

const options = {
    includeScore: true,
    // Search in `author` and in `tags` array
    keys: ['prettyName']
}

const fuse = new Fuse(StandardIcons, options)

export const IconPicker = ({ iconId, className, buttonText, onChange }: { iconId?: string, className?: string, buttonText?: string, onChange?: (icon: IconPickerIcon) => void }) => {
    const [isOpen, setIsOpen] = useState(false);

    const [currentIcon, setCurrentIcon] = useState(getIconId(iconId ?? ""));
    const [hoveredIcon, setHoveredIcon] = useState<IconPickerIcon | undefined>(getIconId(iconId ?? ""));
    const [searchMode, setSearchMode] = useState(false);
    const [searchText, setSearchText] = useState("");

    const ref = useRef<HTMLDivElement | null>(
        null,
    );

    const handleClick = (icon: IconPickerIcon) => {
        onChange?.(getIconId(icon.name));
        setCurrentIcon(getIconId(icon.name));
        setIsOpen(false);
    }

    const handleHover = (icon: IconPickerIcon | undefined) => {
        setHoveredIcon(icon);
    }

    const toggleSearchMode = () => {
        setSearchMode(mode => !mode);
    }

    const cols = 8;
    const popOverWidth = ((cols * (2.5 + 0.5)) - 0.25) + (0.75 * 2);

    const groupedStandardIcons = useMemo(() => {

        let iconArr: IconPickerIcon[] = [];
        if (searchMode && searchText !== "") {
            iconArr = fuse.search(searchText).map(result => result.item);
        } else {
            iconArr = StandardIcons;
        }

        let i = 0;
        const groups: { row: IconPickerIcon[], index: number }[] = [];
        for (let i = 0; i < iconArr.length; i += cols) {
            groups.push({
                index: i,
                row: iconArr.slice(i, i + cols)
            });
            i++;
        }
        return groups;
    }, [StandardIcons, cols, searchText, searchMode]);

    return (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger className={className}>
                <Button variant="outline" className="w-full text-left flex items-start justify-start gap-3 px-2">
                    {buttonText ? <span>{buttonText ?? ""}</span> : null}
                    {currentIcon?.icon}
                </Button>
            </PopoverTrigger>
            <PopoverContent className={`flex flex-col gap-4 p-3`} style={{ width: popOverWidth + "rem" }}>
                <div className={`w-full h-10 text-center p-0 text-md flex gap-3`}>
                    {searchMode ?
                        <Input autoFocus={true} type="text" onChange={(e) => setSearchText(e.target.value)} placeholder="Search..." />
                        : <div className="bg-slate-500/25 p-2 w-full h-full rounded-md font-bold select-none">
                            {hoveredIcon ?
                                <div className="flex">
                                    {hoveredIcon.icon}
                                    <span className="w-full text-center">{hoveredIcon.prettyName}</span>
                                </div>
                                :
                                ""
                            }
                        </div>
                    }
                    <Button variant={searchMode ? "default" : "outline"} className="p-2 h-full select-none" onClick={toggleSearchMode}>
                        <Search />
                    </Button>
                </div>
                <div className="flex flex-wrap gap-2 max-h-56 overflow-y-auto" ref={ref}>
                    <ViewportList
                        viewportRef={ref}
                        items={groupedStandardIcons}
                    >
                        {(groupedIconObj) => (
                            <div className="item flex gap-2" key={groupedIconObj.index}>
                                {groupedIconObj.row.map((iconObj) => (
                                    <IconTile
                                        key={iconObj.name}
                                        iconId={iconObj.name}
                                        className="cursor-pointer item"
                                        onHoverEnter={() => handleHover(iconObj)}
                                        onHoverLeave={() => handleHover(undefined)}
                                        onClick={() => handleClick(iconObj)}
                                        enableTooltips={searchMode}
                                    />
                                ))}
                            </div>
                        )}
                    </ViewportList>
                </div>
            </PopoverContent>
        </Popover>
    )
}

const IconTile = ({ iconId, className = "", onHoverEnter, onHoverLeave, onClick, enableTooltips = false }: { iconId: string, className?: string, onHoverEnter?: () => void, onHoverLeave?: () => void, onClick?: () => void, enableTooltips?: boolean }) => {
    const icon = getIconId(iconId ?? "");

    const tile = (
        <div
            className={[
                "w-10",
                "h-10",
                "flex",
                "items-center",
                "justify-center",
                "select-none",
                "rounded-sm",
                "hover:bg-slate-500/20",
                className
            ].join(" ")}
            onMouseEnter={onHoverEnter}
            onMouseLeave={onHoverLeave}
            onClick={onClick}
        >
            {icon?.icon}
        </div>
    );

    if (enableTooltips) {
        return (
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        {tile}
                    </TooltipTrigger>
                    <TooltipContent>
                        <p>{icon?.prettyName}</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        )
    } else {
        return tile;
    }
}

export const getIconId = (iconId: string): IconPickerIcon => {
    if (iconId === "") {
        iconId = "Smile";
    }
    return StandardIcons.find(icon => icon.name.toLowerCase() === iconId.toLowerCase())!;
}