
import pako from "pako";
import { GameController, SerializedGameSave } from "./GameController";
import { PNGCoder } from "@/lib/PNGCoder";
import { CanvasDrawer } from "./CanvasDrawer";

export const EncodeData = (data: SerializedGameSave): Uint8Array => {
    const b64Encoded = btoa(JSON.stringify(data));
    const compressedData = pako.deflate(b64Encoded);
    return compressedData;
};

export const DecodeData = (data: Uint8Array): SerializedGameSave => {
    const decompressedData = pako.inflate(data);

    const decoder = new TextDecoder();
    const b64Str = decoder.decode(decompressedData);

    const b64Decoded = atob(b64Str);
    return JSON.parse(b64Decoded);
};

export const EncodedDataToString = (data: Uint8Array): string => {
    var string = "";
    for (let i = 0; i < data.length; i++) {
        string += String.fromCharCode(data[i]);
    }
    return string;
};

export const EncodedDataToUInt8Arr = (data: string): Uint8Array => {
    var arr = new Uint8Array(data.length);
    for (let i = 0; i < data.length; i++) {
        arr[i] = data.charCodeAt(i);
    }
    return arr;
};

export const DownloadBlob = function (data: Uint8Array, fileName: string, mimeType: string = "application/octet-stream") {
    var blob, url: string;
    blob = new Blob([data], {
        type: mimeType
    });
    url = window.URL.createObjectURL(blob);
    DownloadURL(url, fileName);
    setTimeout(function () {
        return window.URL.revokeObjectURL(url);
    }, 1000);
};

const DownloadURL = function (data: string, fileName: string) {
    var a;
    a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.click();
    a.remove();
};

export const PromptUpload = async () => {
    return new Promise((resolve, reject) => {
        var input = document.createElement('input');
        input.type = 'file';
        input.click();
        input.onchange = function () {
            if (!input.files || input.files.length === 0) {
                reject();
                return;
            }
            var file = input.files[0];
            var reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async () => {
                var arrayBuffer = reader.result;
                var blob = new Blob([arrayBuffer as ArrayBuffer]);
                resolve(new Uint8Array(await blob.arrayBuffer()));
            };
        };
    });
}

export const getRemoteImage = async (): Promise<void> => {
    const canvas = new CanvasDrawer();
    const blob = await canvas.toBlob();
    const imgBlob = new Uint8Array(await blob.arrayBuffer());
    console.log(imgBlob);
    const pngCoder = new PNGCoder(imgBlob);
    const saveData = GameController.getInstance().getSaveData();
    const fullSaveData = new Uint8Array([
        ..."STONKS-SAVEDATA".split('').map(char => char.charCodeAt(0)),
        ...pngCoder.UInt32ToBytes(saveData.length),
        ...saveData
    ]);
    pngCoder.addChunk(fullSaveData);
    DownloadBlob(pngCoder.serialize(), "stonks-savegame.png", "image/png");
};