import {
    BookCopy,
    BookHeart,
    Cloud,
    Github,
    Keyboard,
    LifeBuoy,
    LogOut,
    Pencil,
    Save,
    Settings,
    Trash2,
    User,
} from "lucide-react"

import { Button } from "@/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { LightModeDropdown, LightModeIcon } from "./ModeToggle";
import { InternalTools } from "./internal-tools/InternalTools";
import { GameController } from "@/objects/GameController";
import { SaveFileMenu } from "./SaveFileMenu";
import { AboutDialog } from "../about/AboutDialog";
import { OpenSourceDialog } from "../about/OpenSourceDialog";
import { useState } from "react";
import { getRemoteImage } from "@/objects/SaveController";
import { AvatarPicker } from "../ui/avatarPicker";
import { useGameMetadata } from "@/objects/useGameMetadata";
import { formatDistance } from "date-fns";

export function UserMenu() {
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    size="icon"
                    className="ghost rounded-full"
                >
                    {/* <User size={16} /> */}
                    <AvatarPicker className="h-8 w-8" canEdit={false} props={GameController.metadata.player?.avatar} />
                    <span className="sr-only">User Menu</span>
                </Button>
            </DropdownMenuTrigger>
            <UserMenuDropdown />
        </DropdownMenu>
    );
}

function UserMenuDropdown() {
    const { metadata } = useGameMetadata();

    const [isAboutDialogOpen, setAboutDialogOpen] = useState(false);
    const onAboutOpenChange = (state: boolean) => {
        setAboutDialogOpen(state);
    }

    const [isOpenSourceDialogOpen, setOpenSourceDialogOpen] = useState(false);
    const onOpenSourceOpenChange = (state: boolean) => {
        setOpenSourceDialogOpen(state);
    }

    return (
        <>
            <DropdownMenuContent className="w-56">
                <DropdownMenuGroup>
                    <div className="p-2 py-4 flex items-center gap-3">
                        <AvatarPicker canEdit={false} props={GameController.metadata.player?.avatar} />
                        <div className="flex flex-col">
                            <span className="font-bold">{GameController.metadata.player?.name ?? "Player"}</span>
                            <span className="text-sm text-slate-400">{formatDistance(new Date(GameController.metadata.createdOn), new Date())} Old</span>
                        </div>
                    </div>
                </DropdownMenuGroup>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                    <DropdownMenuSub>
                        <DropdownMenuSubTrigger>
                            <LightModeIcon className="mr-2 h-4 w-4" />
                            <span>Theme</span>
                        </DropdownMenuSubTrigger>
                        <DropdownMenuPortal>
                            <DropdownMenuSubContent>
                                <LightModeDropdown />
                            </DropdownMenuSubContent>
                        </DropdownMenuPortal>
                    </DropdownMenuSub>
                </DropdownMenuGroup>
                <SaveFileMenu />
                <DropdownMenuSeparator />
                {metadata.isDeveloper && <>
                    <InternalTools />
                    <DropdownMenuSeparator />
                </>}
                <DropdownMenuItem onClick={() => window.open("https://github.com/mfoulks3200/stonks", "_blank")}>
                    <Github className="mr-2 h-4 w-4" />
                    <span>GitHub</span>
                </DropdownMenuItem>
                <DropdownMenuSub>
                    <DropdownMenuSubTrigger>
                        <Pencil className="mr-2 h-4 w-4" />
                        <span>About</span>
                    </DropdownMenuSubTrigger>
                    <DropdownMenuPortal>
                        <DropdownMenuSubContent className="w-56">
                            <DropdownMenuItem onClick={() => setAboutDialogOpen(true)}>
                                <BookHeart className="mr-2 h-4 w-4" />
                                <span>About Stonks.sh</span>
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={() => setOpenSourceDialogOpen(true)}>
                                <BookCopy className="mr-2 h-4 w-4" />
                                <span>Open Source</span>
                            </DropdownMenuItem>
                        </DropdownMenuSubContent>
                    </DropdownMenuPortal>
                </DropdownMenuSub>
                <DropdownMenuSeparator />
                <DropdownMenuItem>
                    <LogOut className="mr-2 h-4 w-4" />
                    <span>Log out</span>
                    <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
                </DropdownMenuItem>
                {/* <DropdownMenuItem onClick={() => getRemoteImage()}>
                    <LogOut className="mr-2 h-4 w-4" />
                    <span>Img Blob</span>
                </DropdownMenuItem> */}
            </DropdownMenuContent>
            <AboutDialog isOpen={isAboutDialogOpen} onOpenChange={onAboutOpenChange} />
            <OpenSourceDialog isOpen={isOpenSourceDialogOpen} onOpenChange={onOpenSourceOpenChange} />
        </>
    );
}