import { DateRange } from "react-day-picker";
import { DateTimePicker } from "../../interface/DateTimePicker";
import { Card, CardContent } from "../../ui/card";
import { StonkGraphConfiguration } from "./StonkGraph";
import { useState } from "react";

export const StonkGraphController = ({ graphConfig, onConfigChange }: { graphConfig: StonkGraphConfiguration, onConfigChange: (graphConfig: StonkGraphConfiguration) => void }) => {

    const [config, setConfig] = useState<StonkGraphConfiguration>(graphConfig);

    const onDateChange = (date: DateRange | undefined) => {
        const newConfig = {
            ...config,
            ...(date ? { from: date?.from } : {}),
            ...(date ? { to: date?.to } : {}),
        };
        setConfig(newConfig);
        onConfigChange(newConfig);
    }

    return (
        <Card className="h-16">
            <CardContent className="p-3 w-full h-full flex gap-4 items-center">
                <DateTimePicker defaultDate={{ from: graphConfig.from, to: graphConfig.to }} onChange={onDateChange} />
            </CardContent>
        </Card>
    );
}