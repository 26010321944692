import { GameController } from "@/objects/GameController";
import { NewGameDialog, NewGameDialogProps } from "./NewGameDialog";
import { useEffect, useState } from "react";
import { Effects } from "../effects/EffectController";

export const OOBE = () => {

    const [shouldRunOOBE, setShouldRunOOBE] = useState(false);

    useEffect(() => {
        if (GameController.isReady) {
            setShouldRunOOBE(!GameController.metadata.hasCompletedOOBE);
        }
    }, [GameController.isReady, GameController.metadata.hasCompletedOOBE]);

    const handleNewGameDialogSubmit = (props: NewGameDialogProps) => {
        GameController.metadata.player = {
            avatar: props.avatarProps,
            name: props.name
        };
        GameController.metadata.hasCompletedOOBE = true;
        GameController.getInstance().save(false);
        setShouldRunOOBE(false);
        setTimeout(() => Effects.confetti.cb(100), 400);
    }

    return (
        <NewGameDialog isOpen={shouldRunOOBE} onSubmit={handleNewGameDialogSubmit} />
    );
}