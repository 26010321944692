import { predicates, objects } from "friendly-words";
import companyAssociations from "../generated/associations.json";

const companyTypes = Object.keys(companyAssociations.companyTypes)

export interface StonkAssociations {
    energy: number;
    materials: number;
    industrials: number;
    utilities: number;
    healthcare: number;
    financials: number;
    consumerDiscretionary: number;
    consumerStaples: number;
    informationTechnology: number;
    communicationServices: number;
    realEstate: number;
}

interface CompanyName {
    name: string;
    associations: StonkAssociations;
}

export const GenerateCompanyName = () => {
    let companyName = [];
    if (Math.random() > 0.5) {
        companyName.push(capitalizeFirstLetter(getRandomFromList(predicates)));
    }
    companyName.push(capitalizeFirstLetter(getRandomFromList(objects)));
    companyName.push(capitalizeFirstLetter(getRandomFromList(companyTypes)));
    return {
        name: companyName.join(" "),
        associations: getAssociations(companyName.join(" "))
    }
};

const getAssociations = (companyName: string) => {
    const components = companyName.split(" ");
    let companyType = "";
    let objectType = "";
    if (components.length === 3) {
        companyType = components[2];
        objectType = components[1];
    } else {
        companyType = components[1];
        objectType = components[0];
    }
    let companyTypeAssociation: StonkAssociations = companyAssociations.companyTypes[companyType as keyof typeof companyAssociations.companyTypes] as StonkAssociations ?? 0;
    let objectTypeAssociation = companyAssociations.objectTypes[objectType as keyof typeof companyAssociations.objectTypes] as StonkAssociations ?? 0;
    return combineAssociations([companyTypeAssociation, objectTypeAssociation]);
};

const combineAssociations = (associations: StonkAssociations[]) => {
    let combinedAssociations: StonkAssociations = {
        energy: 0,
        materials: 0,
        industrials: 0,
        utilities: 0,
        healthcare: 0,
        financials: 0,
        consumerDiscretionary: 0,
        consumerStaples: 0,
        informationTechnology: 0,
        communicationServices: 0,
        realEstate: 0
    }
    for (let key of Object.keys(combinedAssociations)) {
        let value = 0;
        for (let association of associations) {
            value += association[key as keyof StonkAssociations] ?? 0;
        }
        combinedAssociations[key as keyof StonkAssociations] = (value / associations.length);
    }
    return combinedAssociations;
};

const getRandomFromList = (list: string[]) => {
    return list[Math.floor(Math.random() * list.length)];
};

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}