import { NumericalRecord } from "@/objects/NumericalRecord";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { useNumericalRecord } from "@/objects/useNumericalRecord";
import BigNumber from "bignumber.js";
import { Button } from "@/components/ui/button";
import { formatLargeNumber } from "@/objects/Utils";
import { Job } from "@/objects/Job";
import { useJob } from "@/objects/useJob";
import { Skeleton } from "./ui/skeleton";
import { Lock } from "lucide-react";
import { EmptyState } from "./interface/EmptyState";
import { IconTooltip } from "./interface/IconTooltip";
import { InfoTooltip } from "./interface/InfoTooltip";
import { FastFood } from "@/objects/jobs/FastFood";

export function JobCard({ recordId }: { recordId: string }) {
    const recordObj = NumericalRecord.all.get(recordId);
    const disabled = recordObj.isDisabled();
    let { value, delta } = useNumericalRecord(recordObj);
    let { canPurchase } = useJob(recordObj);

    const valueBg = new BigNumber(value);

    const tipsNumber = recordObj.iteratorValue
        .dividedBy(60)
        .multipliedBy(recordObj.value)
    const tips = formatLargeNumber(tipsNumber);

    const ips = formatLargeNumber(recordObj.iteratorValue);

    const mpsPercent = tipsNumber.dividedBy(recordObj.targetRecord.getDelta("second")).multipliedBy(100).toFixed(0);

    let formattedValue = valueBg.toFixed(0);

    if (typeof disabled === "string") {
        return (
            <Card className="basis-1/4 select-none">
                <CardHeader className="opacity-50">
                    <CardDescription className="blur-sm">{recordObj.formatOptions.name ?? "Untitled"} Job</CardDescription>
                    <CardTitle>{formattedValue} Hours</CardTitle>
                    <CardDescription>
                        <Skeleton animate={false} className="w-full h-[1em] rounded-md" />
                    </CardDescription>
                </CardHeader>
                <CardContent>
                    <div className="flex gap-x-3">
                        <Button className="select-none basis-full opacity-50" disabled={true}>Buy (${formatLargeNumber(recordObj.buyPrice())})</Button>
                        {typeof disabled === "string" ? <IconTooltip icon={Lock} text={disabled} size="1rem" /> : null}
                    </div>
                </CardContent>
            </Card >
        )
    } else {
        return (
            <Card className="basis-1/4">
                <CardHeader>
                    <CardDescription>{recordObj.formatOptions.name ?? "Untitled"} Job</CardDescription>
                    <CardTitle>{formattedValue} Hours</CardTitle>
                    <CardDescription>
                        <span className="flex gap-x-3">
                            <span className="flex gap-x-1">
                                <span>${tips} Mps</span>
                                <InfoTooltip text="Money Per Second (Total)" size="0.8rem" /></span>
                            <span>/</span>
                            <span className="flex gap-x-1">
                                <span>${ips} Mph</span>
                                <InfoTooltip text="Money Per Hour (Individual)" size="0.8rem" />
                            </span>
                            <span className={mpsPercent === "NaN" || mpsPercent === "Infinity" ? "hidden" : ""}>/</span>
                            <span className={"flex gap-x-1 " + (mpsPercent === "NaN" || mpsPercent === "Infinity" ? "hidden" : "")}>
                                <span>{mpsPercent}%</span>
                                <InfoTooltip text="% of Total Mps" size="0.8rem" />
                            </span>
                        </span>
                    </CardDescription>
                </CardHeader>
                <CardContent>
                    <div className="flex gap-x-3">
                        <div className="flex gap-x-3 basis-full">
                            <Button className="select-none basis-full" onClick={() => recordObj.purchase()} disabled={!canPurchase}>Buy (${formatLargeNumber(recordObj.buyPrice())})</Button>
                            {!canPurchase && recordObj.cantPurchaseReason() !== undefined ? <InfoTooltip text={recordObj.cantPurchaseReason()!} size="1rem" /> : null}
                        </div>
                        {recordObj.value.gt(0) ? <div className="flex gap-x-3 basis-full">
                            <Button className="select-none w-full" onClick={() => recordObj.sell()} disabled={!recordObj.canSell()}>Sell (${formatLargeNumber(recordObj.sellPrice())})</Button>
                        </div> : <></>}
                    </div>
                </CardContent>
            </Card>
        )
    }
}