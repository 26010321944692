import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"
import { useState } from "react"
import { CalendarIcon } from "lucide-react"
import { DateRange, SelectRangeEventHandler } from "react-day-picker"
import { addDays, format } from "date-fns"
import { Input } from "../ui/input"
import "./DateTimePicker.css"



export function DateTimePicker({ defaultDate, onChange }: { defaultDate: DateRange, onChange?: (date: DateRange | undefined) => void }) {
    const [date, setDate] = useState<DateRange>(defaultDate);
    const [startTime, setStartTime] = useState<string>(`${defaultDate?.from?.getHours()}:${defaultDate?.from?.getMinutes().toString().padStart(2, "0")}`);
    const [endTime, setEndTime] = useState<string>(`${defaultDate?.to?.getHours()}:${defaultDate?.to?.getMinutes().toString().padStart(2, "0")}`);

    const changeStartTime = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartTime(e.target.value);
        const newDate = date;
        newDate.from?.setHours(Number(e.target.value.split(":")[0]));
        newDate.from?.setMinutes(Number(e.target.value.split(":")[1]));
        setDate(newDate);
        onChange?.(newDate);
    };

    const changeEndTime = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndTime(e.target.value);
        const newDate = date;
        newDate.to?.setHours(Number(e.target.value.split(":")[0]));
        newDate.to?.setMinutes(Number(e.target.value.split(":")[1]));
        setDate(newDate);
        onChange?.(newDate);
    };

    const changeDateRange = (range: DateRange | undefined) => {
        if (range) {
            setDate(range);
            onChange?.(range);
        }
    }

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={"outline"}
                    className={cn(
                        "justify-start text-left font-normal",
                        !date && "text-muted-foreground"
                    )}
                >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {date?.from ? (
                        date.to ? (
                            <span>
                                {format(date.from, "LLL dd, p")} -{" "}
                                {format(date.to, "LLL dd, p")}
                            </span>
                        ) : (
                            <span>format(date.from, "LLL dd, p")</span>
                        )
                    ) : (
                        <span>Pick a date</span>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0 flex flex-col pb-4 gap-2 justify-center">
                <Calendar
                    initialFocus
                    mode="range"
                    defaultMonth={date?.from}
                    selected={date}
                    onSelect={changeDateRange}
                    numberOfMonths={2}
                />
                <div className="flex px-4 gap-2 justify-center">
                    <Input
                        type="time"
                        value={startTime}
                        onChange={changeStartTime}
                        placeholder="Start"
                    />
                    <Input
                        type="time"
                        value={endTime}
                        onChange={changeEndTime}
                        placeholder="End"
                    />
                </div>
            </PopoverContent>
        </Popover >
    )
}