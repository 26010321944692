import { Type } from "typescript";

export interface SerializedTypeInformation {
    inheritanceTree: string[];
}

export interface SerializedRecord {
    id: string;
    type: SerializedTypeInformation;
}

export interface Serializable {
    inheritanceTree: string[];
    serialize(): SerializedRecord;
}

export type ConstructorFactory = new (...args: any[]) => any;

export interface Deserializer<T> {
    deserialize(data: SerializedRecord, constructor?: ConstructorFactory): any;
    typeName: string;
    factory: ConstructorFactory;
}

export class SerializationManager {

    public static deserializers: Map<string, Deserializer<any>> = new Map();

    public static serialize(record: Serializable): SerializedRecord {
        return record.serialize();
    }

    public static deserialize(data: SerializedRecord): any {
        for (let type of data.type.inheritanceTree.reverse()) {
            const deserializer = SerializationManager.deserializers.get(type);
            if (!deserializer) {
                continue;
            }
            data.type.inheritanceTree.reverse()
            return deserializer.deserialize(data, deserializer.factory);
        }
        console.log("No deserializer found for object " + data);
    }

    public static registerDeserializer(deserializer: Deserializer<any>): void {
        SerializationManager.deserializers.set(deserializer.typeName, deserializer);
    }
}