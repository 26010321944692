import BigNumber from "bignumber.js";
import { Purchasable, SerializedPurchasable } from "./Purchasable";
import { NumericalFormatOptions, NumericalRecord } from "./NumericalRecord";
import { ConstructorFactory } from "./Serializable";

export interface SerializedJob extends SerializedPurchasable { }

export class Job extends Purchasable {

    public static allJobs: Map<string, Job> = new Map();
    public static maxValue = new BigNumber(10);

    constructor(id: string, value: string | number | BigNumber = 0, formatOptions?: NumericalFormatOptions) {
        super(id, value, formatOptions);
        this.setTargetRecord("money");
        NumericalRecord.all.set(id, this as Job);
        Job.allJobs.set(id, this);
        this.inheritanceTree.push(super.constructor.name);
    }

    public static getTotalValue(): BigNumber {
        let totalValue = new BigNumber(0);
        for (let job of Job.allJobs.values()) {
            totalValue = totalValue.plus(job.value);
        }
        return totalValue;
    }

    public canPurchase(value: BigNumber = new BigNumber(1)): boolean {
        return super.canPurchase(value) && Job.getTotalValue().plus(value).lte(Job.maxValue);
    }

    public cantPurchaseReason(value: BigNumber = new BigNumber(1)): string | undefined {
        if (Job.getTotalValue().plus(value).gt(this.maxValue)) {
            return `Cannot currently work more than ${this.maxValue.toFixed(0)} hours a week`;
        } else if (this.getTargetRecord().value.lt(this.cost)) {
            return "Cannot Afford!";
        }
    }

    public static deserialize(data: SerializedJob, constructor?: ConstructorFactory): any {
        let record;
        if (!constructor) {
            record = new Job(data.id, data.value, data.formatOptions ? data.formatOptions : {});
            record.targetRecord = NumericalRecord.get(data.targetRecordId, 0);
        } else {
            new constructor();
            record = NumericalRecord.all.get(data.id);
            record.value = new BigNumber(data.value);
            record.strValue = record.value.toString();
        }
        record.strAllTimeValue = data.allTimeValue;
        record.allTimeValue = new BigNumber(data.allTimeValue);
        record.datapoints = data.datapoints;
        record.iteratorValue = new BigNumber(data.iteratorValue);
        record.costScalingFactor = new BigNumber(data.costScalingFactor);
        record.allTimeGeneratedValue = new BigNumber(data.allTimeGeneratedValue);
        record.inheritanceTree = data.type.inheritanceTree;
        return record;
    }

}