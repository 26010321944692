import { GameController } from "@/objects/GameController";
import { NumericalRecord } from "@/objects/NumericalRecord";
import { useNumericalRecord } from "@/objects/useNumericalRecord";
import { formatLargeNumber } from "@/objects/Utils";
import BigNumber from "bignumber.js";
import { ChevronDown, ChevronUp } from "lucide-react";

export const NumericalRecordWidget = ({ recordId }: { recordId: string }) => {
    let { value, delta } = useNumericalRecord(NumericalRecord.get(recordId));
    const valueBg = new BigNumber(value);
    const deltaBg = new BigNumber(delta);

    let formattedValue = formatLargeNumber(valueBg);
    let formattedDelta = formatLargeNumber(deltaBg.multipliedBy(GameController.tickRate));

    const isPositive = deltaBg.multipliedBy(GameController.tickRate).isGreaterThan(0);
    const isZero = deltaBg.multipliedBy(GameController.tickRate).isEqualTo(0);

    const deltaIcon = isZero ? "" : (isPositive ? "▲" : "▼");

    return (
        <div className="flex flex-col gap-1">
            <div className="text-xs font-normal">Account Balance</div>
            <div className="flex gap-1 justify-between">
                <div className="text-xs fixed">${formattedValue}</div>
                <div className="text-xs font-mono opacity-0 select-none pointer-events-none">${formattedValue}</div>
                <div className={"text-xs" + (isZero ? "" : (isPositive ? " text-green-500" : " text-red-500"))}>{deltaIcon}{formattedDelta}</div>
            </div>
        </div>
    );
}