import { Stonk } from "@/objects/Stonk";
import useOnScreen from "@/objects/useOnScreen";
import { useStonk } from "@/objects/useStonk";
import { useRef } from "react";
import { Skeleton } from "../../ui/skeleton";
import { DeltaLabel } from "../../interface/DeltaLabel";

export const StonkBrowserItem = ({ stonk, selected, onClick }: { stonk: Stonk, selected: boolean, onClick?: (stonk: Stonk) => void }) => {

    //(isPositive ? "▲" : "▼")
    const ref = useRef<HTMLDivElement>(null)
    const isVisible = useOnScreen(ref)
    const { value, delta } = useStonk(stonk, (1000 * 60 * 60), !isVisible && !selected);

    let valueElement = <></>;
    if (isVisible || selected) {
        valueElement = <>
            <span className="text-xl font-light">${value.toFixed(2)}</span>
            <DeltaLabel delta={delta} className="text-xs" />
        </>;
    } else {
        valueElement = <>
            <Skeleton className="w-[100px] h-[28px]" />
            <Skeleton className="w-[100px] h-[16px]" />
        </>;
    }

    return (
        <div ref={ref} className={"p-4 flex gap-2 border-b cursor-pointer transition-colors hover:bg-muted/50 " + (selected ? "bg-muted/50" : "")} onClick={() => onClick ? onClick(stonk) : null}>
            <div className="flex-1 flex flex-col justify-center">
                <span className="text-3xl font-light">{stonk.ticker}</span>
                <span>{stonk.name}</span>
            </div>
            <div className="flex-1 flex flex-col justify-center items-end gap-2">
                {valueElement}
            </div>
        </div>
    );
}