import { EmptyState } from "@/components/interface/EmptyState";
import { NumericalRecordCard } from "@/components/NumericalRecordCard";
import { NumericalRecordTable } from "@/components/NumericalRecordTable";
import { StonkBrowser } from "@/components/stonks/stonk-browser/StonkBrowser";
import { StonkInfoPanel } from "@/components/stonks/stonk-browser/StonkInfoPanel";
import { StonkGraph } from "@/components/stonks/stonk-graph/StonkGraph";
import { StonkGraphCanvas } from "@/components/stonks/stonk-graph/StonkGraphCanvas";
import { Card, CardContent } from "@/components/ui/card";
import { NumericalRecord } from "@/objects/NumericalRecord";
import { Stonk } from "@/objects/Stonk";
import { BadgeInfo, ChartCandlestick } from "lucide-react";
import { useState } from "react";

export const StonkMarket = () => {

    const [selectedStonks, setSelectedStonks] = useState<Stonk[]>([]);

    return (
        <div className="p-6 flex flex-col gap-6 w-full">
            <div className="flex gap-6 w-full h-full max-h-screen">
                <StonkBrowser onSelectionChange={(selection) => setSelectedStonks(selection)} />
                <StonkGraph emptyState={<EmptyState icon={ChartCandlestick} text="Select a Stonk" wrap={false} />} selectedStonks={selectedStonks} />
            </div>
            <StonkInfoPanel stonk={selectedStonks.length == 1 ? selectedStonks[0] : undefined} />
        </div >
    );
};