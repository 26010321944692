import { LucideProps } from "lucide-react";
import { Card, CardContent } from "../ui/card";

export const EmptyState = ({ icon: Icon, text, wrap = true }: { icon: React.ForwardRefExoticComponent<Omit<LucideProps, "ref"> & React.RefAttributes<SVGSVGElement>>, text: string, wrap?: boolean }) => {

    const contents = <div className="py-16 flex flex-col gap-4 items-center justify-center">
        <Icon className="opacity-5" size={128} />
        <div className="text-3xl font-heavy font-bold opacity-5">{text}</div>
    </div>;
    if (wrap) {
        return (
            <Card className="basis-full h-full flex-auto">
                <CardContent className="p-4 py-16 gap-4 flex flex-col h-full w-full items-center justify-center select-none">
                    {contents}
                </CardContent>
            </Card>
        );
    } else {
        return <div className="gap-4 flex flex-col h-full w-full items-center justify-center select-none">
            {contents}
        </div>;
    }
}