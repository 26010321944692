import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";

export const AboutDialog = ({ isOpen, onOpenChange }: { isOpen: boolean, onOpenChange?: (state: boolean) => void }) => {

    const [isStateOpen, setIsStateOpen] = useState(isOpen);

    useEffect(() => {
        setIsStateOpen(isOpen);
    }, [isOpen]);

    const onInternalOpenChange = (state: boolean) => {
        if (onOpenChange) {
            onOpenChange(state);
        }
    }

    return (
        <Dialog open={isStateOpen} onOpenChange={onInternalOpenChange}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>About Stonks.sh</DialogTitle>
                    <DialogDescription>
                        Stonks.sh is a web-based incremental game that allows you to trade fake stocks for fun. It was created by Pup Atlas!~ of <a href="https://atlaspuplabs.com" target="_blank" className="text-blue-700 underline">Atlas Pup Labs</a>.
                    </DialogDescription>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    );
};