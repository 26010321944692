import { cn } from "@/lib/utils"

function Skeleton({
  className,
  animate = true,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & { animate?: boolean }) {
  return (
    <div
      className={cn([(animate ? "animate-pulse" : null), "rounded-md", "bg-muted"].join(" "), className)}
      {...props}
    />
  )
}

export { Skeleton }
