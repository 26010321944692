import { start } from 'repl';
import packageInfo from '../../package.json';
import { NumericalRecord, SerializedNumericalRecord } from './NumericalRecord';
import { DecodeData, EncodeData, EncodedDataToString, EncodedDataToUInt8Arr } from './SaveController';
import { toast } from 'sonner';
import { Purchasable, SerializedPurchasable } from './Purchasable';
import { FastFood } from './jobs/FastFood';
import { PackageCourrier } from './jobs/PackageCourrier';
import { GenerateCompanyName } from './NameGenerator';
import { SerializedStonk, Stonk } from './Stonk';
import { SerializedStonkIndustry, StonkIndustry } from './stonk-layers/StonkIndustry';
import { ConstructorFactory, SerializationManager, SerializedRecord } from './Serializable';
import { Job } from './Job';
import { AvatarProps } from '@/components/ui/avatarPicker';

if (module.hot) {
    module.hot.addDisposeHandler(() => {
        console.log("======= HOT RELOADING GAME =======");
        GameController.getInstance().save(false);
        GameController.getInstance().stopGame();
    });
}

export interface SerializedGameSave {
    metadata: GameMetadata;
    records: {
        [key: string]: SerializedRecord;
    };
    stonks: {
        individual: {
            [key: string]: SerializedStonk;
        };
        industries: {
            [key: string]: SerializedStonkIndustry;
        };
    };
}

interface PlayerMetadata {
    avatar: AvatarProps;
    name: string;
}

interface GameMetadata {
    version: string;
    createdOn: number;
    lastUpdated: number;
    hasCompletedOOBE: boolean;
    isDeveloper: boolean;
    hasEverEnabledDevMode: boolean;
    player?: PlayerMetadata;
}

interface GameHook {
    id: string;
    hook: () => any;
    interval: number;
    lastRun?: number;
}

interface GameStateWindowVar {
    devMode: boolean;
    state?: any;
}

declare interface window {
    __GAME__: GameStateWindowVar
}

const defaultGameStateWindowVar: GameStateWindowVar = {
    devMode: false
};

const defaultGameMetadata: () => GameMetadata = () => {
    return {
        version: packageInfo.version,
        createdOn: Date.now(),
        lastUpdated: Date.now(),
        isDeveloper: false,
        hasEverEnabledDevMode: false,
        hasCompletedOOBE: false
    }
};

const getWindowVar = (): any => {
    if (!(window as any).__GAME__) {
        (window as any).__GAME__ = defaultGameStateWindowVar;
    }
    return (window as any).__GAME__;
}

export class GameController {
    private static instance: GameController;
    public static metadata: GameMetadata;
    private static started: boolean = false;
    public static gameVersion: string = packageInfo.version;
    //@ts-expect-error
    public static devMode = __mode__ == "development";
    public static previousDevMode: boolean = GameController.devMode;
    public static isReady: boolean = false;

    public static tickRate: number = 15;
    public static uiTickRate: number = GameController.tickRate;
    public static dataLoggingTickRate: number = 1;
    private static tickInterval: any;

    private static globalHooks: GameHook[] = [];

    public static autoSaveRate: number = 1000 * 60 * 5;
    public static autoSaveInterval: any;

    private constructor(metadata?: GameMetadata) {
        GameController.metadata = metadata ?? defaultGameMetadata();
        GameController.devMode = getWindowVar().devMode;
        this.startGame();
        if (!GameController.instance) {
            GameController.instance = this;
        }
        if (GameController.devMode) {
            GameController.toggleDevMode(true);
        }
    }

    public static getInstance(): GameController {
        if (!GameController.instance) {
            GameController.instance = new GameController();
        }

        return GameController.instance;
    }

    public static toggleDevMode(enabled: boolean): void {
        console.log(`${enabled ? "Enabling" : "Disabling"} dev mode... ${enabled ? "(This will permanently mark your save file)" : ""}`);
        GameController.devMode = enabled;
        GameController.metadata.isDeveloper = enabled;
        getWindowVar().devMode = enabled;
        if (enabled) {
            (window as any).__GAME__.state = GameController.getInstance().getDevObject();
            GameController.metadata.hasEverEnabledDevMode = true;
        } else {
            delete (window as any).__GAME__.state;
        }
        GameController.previousDevMode = enabled;
        GameController.getInstance().save(false);
    }

    public startGame(): void {
        if (GameController.started) {
            return;
        }

        console.log('Game started!');
        this.setupDefaultRecords();
        this.setupDeserializers();
        this.load();
        GameController.tickInterval = setInterval(() => this.tick(), 1000 / GameController.tickRate);
        GameController.autoSaveInterval = setInterval(() => this.save(), GameController.autoSaveRate);
        GameController.started = true;
        this.save(false);
        GameController.isReady = true;
    }

    public stopGame(): void {
        GameController.isReady = false;
        console.log('Game stopped!');
        clearInterval(GameController.tickInterval);
        GameController.tickInterval = undefined;
        GameController.started = false;
    }

    private tick(): void {
        NumericalRecord.all.forEach((record) => {
            record.processEvents();
        });
        for (let hook of GameController.globalHooks) {
            if ((hook.lastRun && Date.now() - hook.lastRun > hook.interval) || hook.lastRun === undefined) {
                hook.hook();
                hook.lastRun = Date.now();
            }
        }
        if (getWindowVar().devMode !== GameController.previousDevMode) {
            GameController.toggleDevMode(getWindowVar().devMode);
        } else if (GameController.devMode !== GameController.previousDevMode) {
            GameController.toggleDevMode(GameController.devMode);
        }
    }

    private setupDefaultRecords(): void {
        NumericalRecord.get('money', 100, {
            name: 'Money',
            description: 'The amount of money you have.',
            unitName: 'USD'
        }).updateValue(100);
        FastFood.getInstance();
        PackageCourrier.getInstance();
        Stonk.generateRandomStonks();
        StonkIndustry.initialize();
    }

    private setupDeserializers(): void {
        SerializationManager.registerDeserializer({
            deserialize: NumericalRecord.deserialize,
            typeName: "NumericalRecord",
            factory: NumericalRecord.constructor as ConstructorFactory
        });
        SerializationManager.registerDeserializer({
            deserialize: Purchasable.deserialize,
            typeName: "Purchasable",
            factory: Purchasable.constructor as ConstructorFactory
        });
        SerializationManager.registerDeserializer({
            deserialize: Job.deserialize,
            typeName: "Job",
            factory: Job.constructor as ConstructorFactory
        });
        SerializationManager.registerDeserializer({
            deserialize: FastFood.deserialize,
            typeName: "FastFood",
            factory: FastFood.constructor as ConstructorFactory
        });
        SerializationManager.registerDeserializer({
            deserialize: PackageCourrier.deserialize,
            typeName: "PackageCourrier",
            factory: PackageCourrier.constructor as ConstructorFactory
        });
    }

    public static addGlobalHook(hook: GameHook): void {
        if (!GameController.globalHooks.find(h => h.id === hook.id)) {
            GameController.globalHooks.push(hook);
        }
    }

    public static removeGlobalHook(hook: GameHook): void {
        const existingHook = GameController.globalHooks.find(h => h.id === hook.id);
        if (existingHook) {
            GameController.globalHooks = GameController.globalHooks.filter(h => h.id !== hook.id);
        }
    }

    private softReset(): void {
        NumericalRecord.all.clear();
        Stonk.all.clear();
        StonkIndustry.clear();
        GameController.metadata = defaultGameMetadata();
        this.setupDefaultRecords();
    }

    public clearSave(): void {
        this.stopGame();
        localStorage.removeItem('stonks-game-save');
        this.softReset();
        this.save(false);
        console.log("Cleared save file");
        console.log(this.getRawSaveData());
        // eslint-disable-next-line
        location.reload();
    }

    public save(showToast: boolean = true): void {
        const saveData = this.getRawSaveData();
        const saveDataStr = EncodedDataToString(EncodeData(saveData));
        localStorage.setItem('stonks-game-save', saveDataStr);
        console.log("Saving game...")
        if (showToast) {
            toast('Your game has been saved!');
        }
    }

    public load(saveData?: SerializedGameSave): void {
        try {
            console.log("Attempting to load save...");
            if (localStorage.hasOwnProperty('stonks-game-save') && !saveData) {
                const rawSaveData = EncodedDataToUInt8Arr(localStorage.getItem('stonks-game-save')!);
                saveData = DecodeData(rawSaveData) as SerializedGameSave;
            }
            if (saveData) {
                console.log(saveData);
                this.softReset();
                GameController.metadata = saveData.metadata;
                GameController.devMode = saveData.metadata.isDeveloper;
                for (let record of Object.keys(saveData.records)) {
                    let recordData: SerializedRecord = saveData.records[record];
                    SerializationManager.deserialize(recordData);
                }
                StonkIndustry.clear();
                for (let stonk of Object.keys(saveData.stonks.industries)) {
                    let stonkData: SerializedStonkIndustry = saveData.stonks.industries[stonk];
                    StonkIndustry.deserialize(stonkData);
                }
                Stonk.all.clear();
                for (let stonk of Object.keys(saveData.stonks.individual)) {
                    let stonkData: SerializedStonk = saveData.stonks.individual[stonk];
                    Stonk.deserialize(stonkData);
                }
            }
            NumericalRecord.updateAllValues();
        } catch (e: any) {
            console.error(e);
            toast("There was an error loading your save.", {
                description: e.toString(),
            });
        }
    }

    public getRawSaveData(): SerializedGameSave {
        const records: any = {};
        for (const [id, record] of NumericalRecord.all) {
            records[id as string] = SerializationManager.serialize(record);
        }
        const stonks: any = {};
        for (const [id, stonk] of Stonk.all) {
            stonks[id as string] = stonk.serialize();
        }
        const stonkIndustries: any = {};
        for (const [id, stonkIndustry] of StonkIndustry.all) {
            stonkIndustries[id as string] = stonkIndustry.serialize();
        }
        return {
            metadata: GameController.metadata,
            records: records,
            stonks: {
                individual: stonks,
                industries: stonkIndustries
            }
        }
    }

    public getSaveData(): Uint8Array {
        return EncodeData(this.getRawSaveData());
    }

    public getDevObject(): any {
        return {
            controller: GameController,
            records: NumericalRecord
        }
    }
}