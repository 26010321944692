import { useEffect, useState } from "react";

export interface HermodBuild {
    buildNumber: number;
    buildChannel: string;
    buildVersion: string;
    buildCommit: string;
}

type HermodBuildResponse = {
    [key: string]: HermodBuild[];
}

export function useHermodBuilds() {
    const [isLoading, setIsLoading] = useState(true);
    const [requestStarted, setRequestStarted] = useState(false);
    const [builds, setBuilds] = useState<HermodBuildResponse>();

    useEffect(() => {
        if (!requestStarted) {
            setRequestStarted(true);
            fetch("http://stonks.sh/-hermod/builds")
                .then(res => res.json())
                .then(data => {
                    setBuilds(data);
                    setIsLoading(false);
                });
        }
    });

    return {
        isLoading,
        builds,
    }
}