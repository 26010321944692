import { Effects } from "@/components/effects/EffectController"
import { Button } from "@/components/ui/button"
import { ColorPicker, ColorPickerColor } from "@/components/ui/colorPicker"
import { IconPicker, IconPickerIcon } from "@/components/ui/iconPicker"
import { PartyPopper } from "lucide-react"

export const TestPage = () => {
    return (
        <div className="p-4 flex gap-4 items-center justify-center h-full">
            <IconPicker iconId={""} onChange={function (icon: IconPickerIcon): void {
                // throw new Error("Function not implemented.")
            }} />
            <Button variant="outline" className="flex gap-2" onClick={() => Effects.confetti.cb(100)}>
                <PartyPopper />
                Trigger Confetti
            </Button>
        </div>
    )
}