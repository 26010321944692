import { Stonk } from "@/objects/Stonk";
import { StonkGraphController } from "./StonkGraphController";
import { StonkGraphCanvas } from "./StonkGraphCanvas";
import { useState } from "react";
import { DateRange } from "react-day-picker";
import { addDays } from "date-fns";
import { Card, CardContent } from "../../ui/card";

export interface StonkGraphConfiguration {
    from: Date;
    to: Date;
}

const currentDate = new Date();
currentDate.setMinutes(0);

const defaultDateRange: DateRange = {
    from: addDays(currentDate, -7),
    to: currentDate,
}

export const StonkGraph = ({ emptyState, selectedStonks }: { emptyState?: React.ReactNode, selectedStonks: Stonk[] }) => {

    const [graphConfig, setGraphConfig] = useState<StonkGraphConfiguration>({
        from: defaultDateRange.from!,
        to: defaultDateRange.to!,
    });

    return (
        <div className="basis-3/4 flex flex-col gap-6">
            <StonkGraphController graphConfig={graphConfig} onConfigChange={setGraphConfig} />
            <Card className="basis-3/4 h-full flex-auto">
                <CardContent className="p-0 flex h-full w-full overflow-hidden">
                    {selectedStonks.length === 0 ?
                        emptyState
                        :
                        <StonkGraphCanvas
                            startTime={graphConfig.from.getTime()}
                            endTime={graphConfig.to.getTime()}
                            stonks={selectedStonks}
                        />
                    }
                </CardContent>
            </Card>
        </div>
    );
}