import prand from 'pure-rand';
import { sha512 } from 'js-sha512';
import { GenerateCompanyName, StonkAssociations } from './NameGenerator';
import { inflationStonkLayer, marketFluctuationStonkLayer, noiseStonkLayer, StonkLayer } from './stonk-layers/StonkLayer';
import { StonkIndustry } from './stonk-layers/StonkIndustry';
import { NumericalRecord } from './NumericalRecord';

export interface SerializedStonk {
    name: string;
    ticker: string;
    startingPrice?: number;
    startingTime?: number;
    shares?: number;
    rootSeed?: number;
    associations?: StonkAssociations;
    owned?: number;
    purchaseRecord?: PurchaseRecord[];
}

export interface PurchaseRecord {
    time: number;
    price: number;
    qty: number;
    remaining: number;
}

export class Stonk {
    public static all: Map<string, Stonk> = new Map();
    public static globalLayers: StonkLayer[] = [marketFluctuationStonkLayer, noiseStonkLayer, inflationStonkLayer];

    public name: string;
    public ticker: string;
    public startingPrice: number;
    public startingTime: number;
    public shares: number;
    public rootSeed: number;
    public associations: StonkAssociations;

    private generator: prand.RandomGenerator;
    private generators: prand.RandomGenerator[] = [];
    private valueCache: Map<number, { value: number, cachedAt: number }> = new Map();

    public owned: number = 0;
    public purchaseRecord: PurchaseRecord[] = [];

    constructor(name: string, ticker: string, options?: SerializedStonk) {
        this.name = name;
        this.ticker = ticker;
        this.rootSeed = options?.rootSeed ?? Math.random() * (Date.now() / (1000 * 60 * 60 * 24));
        this.generator = prand.xoroshiro128plus(this.rootSeed);
        this.generators.push(this.generator);
        this.startingPrice = options?.startingPrice ?? prand.unsafeUniformIntDistribution(5, 5000, this.generator);
        this.startingTime = options?.startingTime ?? Date.now();
        this.shares = options?.shares ?? prand.unsafeUniformIntDistribution(1e4, 1e10, this.generator);
        this.associations = options?.associations ?? {
            energy: 0,
            materials: 0,
            industrials: 0,
            utilities: 0,
            healthcare: 0,
            financials: 0,
            consumerDiscretionary: 0,
            consumerStaples: 0,
            informationTechnology: 0,
            communicationServices: 0,
            realEstate: 0
        }
        this.owned = options?.owned ?? 0;
        this.purchaseRecord = options?.purchaseRecord ?? [];
        Stonk.all.set(this.ticker, this);
    }

    public getGenerator(i: number): prand.RandomGenerator {
        if (i > this.generators.length - 1) {
            for (let j = this.generators.length - 1; j < i; j++) {
                this.generators.push(this.generators[j].jump!());
            }
        }
        return this.generators[i];
    }

    private pruneCache() {
        const now = Date.now();
        for (const [time, value] of this.valueCache) {
            if (now - value.cachedAt > 1000 * 60 * 60) {
                this.valueCache.delete(time);
            }
        }
    }

    public calculatePrice(time: number): number {
        this.pruneCache();
        if (this.valueCache.has(time)) {
            return this.valueCache.get(time)!.value;
        }
        let value = this.startingPrice;
        for (const layer of Stonk.globalLayers) {
            value = layer(this, value, time, 1);
        }
        value = StonkIndustry.addIndustryInfluence(this, value, time);
        this.valueCache.set(time, {
            value,
            cachedAt: Date.now()
        });
        return value;
    }

    public currentValue(): number {
        return this.calculatePrice(Date.now());
    }

    public delta(time: number): number {
        const currentValue = this.currentValue();
        return (currentValue / (currentValue - this.calculatePrice(time))) * 100;
    }

    public costBasis(): number {
        const purchases = this.purchaseRecord.filter(purchase => purchase.qty > 0);
        let totalQty = purchases.reduce((a, b) => a + b.qty, 0);
        let currentBasis = 0;
        for (let purchase of purchases) {
            currentBasis += purchase.price * (purchase.qty / totalQty);
        }
        return currentBasis;
    }

    public purchase(qty: number): void {
        if (this.owned + qty > this.shares) {
            return;
        }
        if (NumericalRecord.get('money').value.isLessThan(this.currentValue() * qty)) {
            return;
        }
        this.owned += qty;
        this.purchaseRecord.push({
            time: Date.now(),
            price: this.currentValue(),
            qty,
            remaining: this.owned
        });
        NumericalRecord.get('money').decrement(this.currentValue() * qty);
    }

    public sell(qty: number): void {
        if (this.owned - qty < 0) {
            return;
        }
        this.owned -= qty;
        this.purchaseRecord.push({
            time: Date.now(),
            price: this.currentValue(),
            qty: -qty,
            remaining: this.owned
        });
        NumericalRecord.get('money').increment(this.currentValue() * qty);
    }

    public static get(ticker: string): Stonk {
        return Stonk.all.get(ticker)!;
    }

    public serialize(): SerializedStonk {
        return {
            name: this.name,
            ticker: this.ticker,
            startingPrice: this.startingPrice,
            startingTime: this.startingTime,
            shares: this.shares,
            rootSeed: this.rootSeed,
            associations: this.associations,
            purchaseRecord: this.purchaseRecord
        }
    }

    public static deserialize(data: SerializedStonk): Stonk {
        const { name, ticker } = data;
        return new Stonk(name, ticker, data);
    }

    public static generateRandomStonks(n: number = 100): Stonk[] {
        const stonks = [];
        for (let i = 0; i < n; i++) {
            const companyName = GenerateCompanyName();
            const ticker = companyName.name.split(" ").map(word => word[0]).join("").toUpperCase();
            if (!Stonk.all.has(ticker)) {
                stonks.push(new Stonk(companyName.name, ticker, {
                    name: companyName.name,
                    ticker: ticker,
                    associations: companyName.associations
                }));
            }
        }
        return stonks;
    }
}