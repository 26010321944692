import { ConfettiOverlay } from "@/components/effects/Confetti";
import { Effects } from "@/components/effects/EffectController";
import { NavBar } from "@/components/navbar/NavBar";
import { OOBE } from "@/components/oobe/OOBE";
import { Button } from "@/components/ui/button";
import { Toaster } from "@/components/ui/sonner";
import { Outlet } from "react-router";

export const Root = ({ children }: { children?: React.ReactNode }) => {

    return (
        <div className="min-w-full max-w-full min-h-screen flex flex-col">
            <NavBar />
            <div className="App w-full flex flex-col flex-1">
                {children ?? <Outlet />}
            </div>
            <div className="w-full pb-4 text-sm text-muted text-center select-none">
                AtlasPup Labs — {new Date().getFullYear()}
            </div>
            <OOBE />
            <ConfettiOverlay />
            <Toaster closeButton />
        </div>

    );
};