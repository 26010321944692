import { NumericalRecordCard } from "@/components/NumericalRecordCard";
import { NumericalRecordTable } from "@/components/NumericalRecordTable";
import { NumericalRecord } from "@/objects/NumericalRecord";

export const Home = () => {
    return (
        <div className="p-6 flex gap-6">
            <NumericalRecordCard record='money' />
            <NumericalRecordTable />
        </div >
    );
};