import { Button } from "@/components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuRadioGroup, DropdownMenuRadioItem, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Filter } from "lucide-react";
import { useState } from "react";
import { StonkFilter } from "./StonkBrowser";

export const StonkFilterDropdown = ({ stonkFilters, onSelectionChange }: { stonkFilters: StonkFilter[], onSelectionChange?: (filterId: string | undefined) => void }) => {

    const [position, setPosition] = useState<string>();

    const setFilter = (filterId: string | undefined) => {
        setPosition(filterId);
        if (onSelectionChange) {
            onSelectionChange(filterId);
        }
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button variant="outline">
                    <Filter />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
                <DropdownMenuItem onClick={() => setFilter(undefined)}>Clear Filters</DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuRadioGroup value={position} onValueChange={setFilter}>
                    {stonkFilters.map((stonkFilter: StonkFilter) =>
                        <DropdownMenuRadioItem key={stonkFilter.id} value={stonkFilter.id}>{stonkFilter.name}</DropdownMenuRadioItem>
                    )}
                </DropdownMenuRadioGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );
};