import { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import { ChevronRight, PartyPopper, SquarePen } from "lucide-react";
import { AvatarPicker, AvatarProps, AvatarType, defaultAvatarProps } from "../ui/avatarPicker";
import { Input } from "../ui/input";
import { ColorPicker, ColorPickerColor, defaultColorPickerColor } from "../ui/colorPicker";
import { Button } from "../ui/button";
import { IconPicker, IconPickerIcon } from "../ui/iconPicker";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../ui/select";

export interface NewGameDialogProps {
    avatarProps: AvatarProps;
    name: string;
}

export const NewGameDialog = ({ isOpen: isOpenDefault, onSubmit }: { isOpen: boolean; onSubmit?: (props: NewGameDialogProps) => void }) => {

    const [isOpen, setIsOpen] = useState(isOpenDefault);
    const [name, setName] = useState("");
    const [avatarProps, setAvatarProps] = useState<AvatarProps>(defaultAvatarProps);

    useEffect(() => {
        setIsOpen(isOpenDefault);
    }, [isOpenDefault]);

    const handleAvatarColorChange = (color: ColorPickerColor) => {
        setAvatarProps((originalProps: AvatarProps) => {
            return { ...originalProps, color }
        });
    }

    const handleInitialChange = (nameStr: string) => {
        const initials = nameStr.split(" ").slice(0, 2).map(word => word[0]).join("")
        setAvatarProps((originalProps: AvatarProps) => {
            return { ...originalProps, initials }
        });
        setName(nameStr);
    }

    const handleIconChange = (icon: IconPickerIcon) => {
        setAvatarProps((originalProps: AvatarProps) => {
            return { ...originalProps, iconId: icon.name }
        });
    }

    const handleImgChange = (avatar: string) => {
        setAvatarProps((originalProps: AvatarProps) => {
            return { ...originalProps, type: "image", imgData: avatar }
        });
    }

    const handleAvatarTypeChange = (type: string) => {
        setAvatarProps((originalProps: AvatarProps) => {
            return { ...originalProps, type: type as AvatarType }
        });
    }

    const handleSubmit = () => {
        if (onSubmit) {
            onSubmit({
                avatarProps,
                name
            });
        }
        setIsOpen(false);
    }

    return (
        <Dialog open={isOpen}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle className="pb-5 flex gap-4 items-center">
                        <PartyPopper />
                        Welcome to Stonks!
                    </DialogTitle>
                    <DialogDescription className="pb-5 flex flex-col gap-8">
                        <span>Create a profile to get started. Don't worry, you can change this later, and it's only saved locally.</span>
                        <div className="flex gap-8 items-center">
                            <AvatarPicker
                                className="w-32 h-32"
                                props={avatarProps}
                                onChange={handleImgChange}
                            />
                            <div className="flex flex-col gap-4 flex-grow">
                                <Input type="text" placeholder="Name" onChange={e => handleInitialChange(e.target.value)} />
                                <Select value={avatarProps.type} onValueChange={handleAvatarTypeChange}>
                                    <SelectTrigger className="w-full">
                                        <SelectValue placeholder="Avatar Type" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectItem value="image" disabled={avatarProps.imgData === undefined}>Image</SelectItem>
                                            <SelectItem value="icon">Icon</SelectItem>
                                            <SelectItem value="initials" disabled={avatarProps.initials === undefined}>Initials</SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                                <div className="flex gap-3">
                                    <ColorPicker className="w-full" onChange={handleAvatarColorChange} />
                                    <IconPicker onChange={handleIconChange} />
                                    <Button variant="default" onClick={handleSubmit}>
                                        Start Game
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </DialogDescription>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    );
}