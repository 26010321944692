import { JobCard } from "@/components/JobCard";
import { PurchasableCard } from "@/components/PurchasableCard";
import { Job } from "@/objects/Job";
import BigNumber from "bignumber.js";

export const Jobs = () => {

    const jobs = [];
    for (let job of Job.allJobs.values()) {
        if (job.isVisible()) {
            jobs.push(<JobCard recordId={job.id} key={job.id} />);
        }
    }

    return (
        <div className="p-6 flex gap-6">
            {jobs}
        </div>
    );
};