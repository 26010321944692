import { formatLargeNumber } from "@/objects/Utils";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./ui/card";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "./ui/table";
import { Purchasable } from "@/objects/Purchasable";
import { GameController } from "@/objects/GameController";
import { Badge } from "./ui/badge";

export const NumericalRecordTable = () => {

    const records = Array.from(Purchasable.allPurchasable.values()) as Purchasable[];

    const rows = [];
    for (let record of records) {
        rows.push(
            <TableRow key={record.id}>
                <TableCell>{record.formatOptions.name}</TableCell>
                <TableCell><Badge variant="outline">Hourly Job</Badge></TableCell>
                <TableCell className="text-right">{formatLargeNumber(record.value)} Hours</TableCell>
                <TableCell className="text-right">${formatLargeNumber(record.iteratorValue)}</TableCell>
                <TableCell className="text-right">${formatLargeNumber(record.allTimeGeneratedValue)}</TableCell>
                <TableCell className="text-right">{formatLargeNumber(record.iteratorValue.dividedBy(60).multipliedBy(record.value))} Mps</TableCell>
            </TableRow>
        )
    }

    return (
        <Card className="basis-3/4">
            <CardContent className="p-0">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Source</TableHead>
                            <TableHead>Type</TableHead>
                            <TableHead className="text-right">Quantity</TableHead>
                            <TableHead className="text-right">Rate</TableHead>
                            <TableHead className="text-right">All Time Total</TableHead>
                            <TableHead className="text-right">Mps</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {rows}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};