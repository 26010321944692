import React from 'react';
import { GameController } from './objects/GameController';

import './globals.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Root } from './pages/Root';
import { Home } from './pages/Home';
import { Jobs } from './pages/Jobs';
import { ErrorPage } from './pages/ErrorPage';
import { StonkMarket } from './pages/StonkMarket';
import ReactGA from "react-ga4";
import { TestPage } from './pages/TestPage';

function App() {

  const [gameController, setGameController] = React.useState(GameController.getInstance());

  ReactGA.initialize("G-HFE2CVLQ21");

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: (
        <Root>
          <ErrorPage />
        </Root>),
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "jobs",
          element: <Jobs />,
        },
        {
          path: "stonks",
          element: <StonkMarket />,
        },
        {
          ...(GameController.devMode ?
            {
              path: "test",
              element: <TestPage />,
            } : {})
        }
      ],
    },
  ], {
    basename: document.querySelector("base")?.getAttribute("href") ?? "/"
  });

  return (
    <RouterProvider router={router} />
  );
}

export default App;
