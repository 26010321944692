import { useEffect, useState } from "react";
import { GameController } from "./GameController";
import { uuidv4 } from "@/lib/utils";

export const useGameMetadata = (minimumInterval: number = 1000 / GameController.uiTickRate) => {
    const [metadataStr, setMetadataStr] = useState(JSON.stringify(GameController.metadata));
    const [metadata, setMetadata] = useState(GameController.metadata);

    useEffect(() => {
        console.log("Started metadata hook");
        GameController.addGlobalHook({
            id: "game-metadata-" + uuidv4(),
            hook: () => {
                setMetadataStr(JSON.stringify(GameController.metadata));
            },
            interval: minimumInterval
        });
    }, []);

    useEffect(() => {
        setMetadata(GameController.metadata);
    }, [metadataStr]);

    return {
        metadata
    };
}