import { useEffect, useState } from 'react';
import Confetti from 'react-confetti'
import { useWindowSize } from 'react-use';
import { Effects } from './EffectController';

let recycleInterval: any;
let runInterval: any;
let hideInterval: any;

export const ConfettiOverlay = () => {
    const [recycle, setRecycle] = useState(false);
    const [run, setRun] = useState(false);
    const [hide, setHide] = useState(true);

    Effects.confetti.cb = (duration: number) => {
        setRun(true);
        setHide(false);
        setRecycle(true);
        clearTimeout(runInterval);
        clearTimeout(recycleInterval);
        clearTimeout(hideInterval);
        recycleInterval = setTimeout(() => {
            setRecycle(false);
        }, duration);
        hideInterval = setTimeout(() => {
            setHide(true);
        }, duration + 5000);
        runInterval = setTimeout(() => {
            setRun(false);
        }, duration + 6000);
    };

    const { width, height } = useWindowSize()
    return (
        <div className={`fixed w-full h-full top-0 left-0 z-50 pointer-events-none transition-opacity ${hide ? "opacity-0" : "opacity-100"}`}>
            <Confetti
                width={width}
                height={height}
                confettiSource={{
                    x: (width / 2) - 50,
                    y: height,
                    w: 100,
                    h: 50,
                }}
                initialVelocityY={60}
                initialVelocityX={50}
                friction={0.95}
                gravity={0.4}
                recycle={recycle}
                run={run}
                tweenDuration={100}
                numberOfPieces={500}
            />
        </div>
    );
}