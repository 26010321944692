import { Stonk } from "@/objects/Stonk";
import { Card, CardContent } from "../../ui/card";
import { useStonk } from "@/objects/useStonk";
import { DeltaLabel } from "../../interface/DeltaLabel";
import { Button } from "../../ui/button";
import { EmptyState } from "../../interface/EmptyState";
import { BadgeInfo } from "lucide-react";
import { formatLargeNumber } from "@/objects/Utils";
import { DataRow } from "../../interface/DataRow";
import { BuySellWidget } from "../BuySellWidget";
import { Tooltip } from "../../ui/tooltip";
import { InfoTooltip } from "../../interface/InfoTooltip";
import { NumericalRecord } from "@/objects/NumericalRecord";
import { useNumericalRecord } from "@/objects/useNumericalRecord";
import BigNumber from "bignumber.js";
import { StonkAssociationChart } from "../StonkAssociationChart";
import { StonkTransactionHistory } from "../StonkTransactionHistory";

export const StonkInfoPanel = ({ stonk }: { stonk?: Stonk }) => {

    const { value, delta } = useStonk(stonk!, 1000 * 60 * 60, stonk == undefined);
    const { value: money, delta: moneyDelta } = useNumericalRecord(NumericalRecord.get('money'));

    if (stonk) {
        return (
            <div className="flex gap-6 h-full w-full">
                <Card className="basis-3/4 h-full">
                    <CardContent className="p-4 flex flex-col gap-8 h-full w-full flex">
                        <div className="w-full flex gap-8 items-center">
                            <div className="text-8xl font-extralight">{stonk!.ticker}</div>
                            <div className="flex-1 flex flex-col h-full justify-center gap-3 flex-wrap">
                                <div className="text-3xl">{stonk!.name}</div>
                                <div className="flex">
                                    <div className={`text-3xl font-light absolute`}>${value.toFixed(2)}</div>
                                    <div className={`text-3xl font-light font-mono opacity-0 select-none`}>${value.toFixed(2)}</div>
                                    <DeltaLabel delta={delta} className="" />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <div className="text-xl font-bold">Industry Associations</div>
                            <StonkAssociationChart stonk={stonk!} />
                        </div>
                        <div className="flex flex-col gap-4">
                            <div className="text-xl font-bold">Previous Transactions</div>
                            <StonkTransactionHistory stonk={stonk!} />
                        </div>
                    </CardContent>
                </Card>
                <div className="basis-1/4 h-full flex flex-col gap-6">
                    <Card className="w-full h-full">
                        <CardContent className="p-4 flex flex-col gap-4 h-full w-full flex">
                            <div className="flex flex-col gap-2">
                                <DataRow label="You Own" value={stonk!.owned.toString()} />
                                <DataRow label="Cost Basis" value={
                                    stonk!.purchaseRecord.length == 0 ?
                                        (<div className="flex gap-1">
                                            <span>None</span>
                                            <InfoTooltip text={"You haven't purchased this stonk."} size="0.8rem" />
                                        </div>) :
                                        (<div className="flex gap-4">
                                            <span>${stonk!.costBasis().toFixed(2).toString()}</span>
                                            <DeltaLabel delta={stonk!.currentValue() - stonk!.costBasis()} />
                                        </div>)
                                } />
                                <DataRow label="Current Value" value={"$" + (stonk!.owned * stonk!.currentValue()).toFixed(2)} />
                            </div>
                            <BuySellWidget
                                opts={{
                                    label: `Buy ${stonk!.ticker}`,
                                    disabled: BigNumber(money).isLessThan(stonk!.currentValue()),
                                    min: 0,
                                    max: Math.floor(BigNumber(money).dividedBy(stonk!.currentValue()).toNumber()),
                                    onAction: (qty) => stonk!.purchase(qty)
                                }}
                            />
                            <BuySellWidget
                                opts={{
                                    label: `Sell ${stonk!.ticker}`,
                                    disabled: stonk!.owned == 0,
                                    min: 0,
                                    max: stonk!.owned,
                                    onAction: (qty) => stonk!.sell(qty)
                                }}
                            />
                        </CardContent>
                    </Card>

                    <Card className="w-full h-full">
                        <CardContent className="p-4 flex flex-col gap-4 h-full w-full flex">
                            <DataRow label="Ticker" value={stonk!.ticker} />
                            <DataRow label="Name" value={stonk!.name} />
                            <DataRow label="Shares" value={formatLargeNumber(stonk!.shares)} />
                            <DataRow label="Growth (Last Hour)" value={<DeltaLabel delta={delta} />} />
                            <DataRow label="Growth (All Time)" value={<DeltaLabel delta={stonk!.startingPrice - value} />} />
                        </CardContent>
                    </Card>
                </div>
            </div>
        );
    } else {
        return (
            <div className="flex gap-6 h-full w-full">
                <Card className="w-full h-full">
                    <CardContent className="p-32 flex flex-col gap-8 h-full w-full flex">
                        <EmptyState icon={BadgeInfo} wrap={false} text="Select a single stonk to view detailed info" />
                    </CardContent>
                </Card>
            </div>
        );
    }
};