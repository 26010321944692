import { useState } from "react";
import { Button } from "./button";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

export interface ColorPickerColor {
    name: string;
    color: string;
    textColor: string;
    className: string;
    textClassName: string;
}

export const StandardColors: ColorPickerColor[] = [
    {
        name: "Slate",
        color: "#475569",
        textColor: "#fff",
        className: "bg-slate-600",
        textClassName: "text-white"
    },
    {
        name: "Tomato",
        color: "#dc2626",
        textColor: "#fff",
        className: "bg-red-600",
        textClassName: "text-white"
    },
    {
        name: "Tangerine",
        color: "#ea580c",
        textColor: "#000",
        className: "bg-orange-600",
        textClassName: "text-black"
    },
    {
        name: "Amber",
        color: "#d97706",
        textColor: "#000",
        className: "bg-amber-600",
        textClassName: "text-black"
    },
    {
        name: "Straw",
        color: "#ca8a04",
        textColor: "#fff",
        className: "bg-yellow-600",
        textClassName: "text-white"
    },
    {
        name: "Lime",
        color: "#65a30d",
        textColor: "#fff",
        className: "bg-lime-600",
        textClassName: "text-white"
    },
    {
        name: "Fern",
        color: "#16a34a",
        textColor: "#000",
        className: "bg-green-600",
        textClassName: "text-black"
    },
    {
        name: "Emerald",
        color: "#059669",
        textColor: "#fff",
        className: "bg-emerald-600",
        textClassName: "text-white"
    },
    {
        name: "Teal",
        color: "#0d9488",
        textColor: "#fff",
        className: "bg-teal-600",
        textClassName: "text-white"
    },
    {
        name: "Cyan",
        color: "#0891b2",
        textColor: "#000",
        className: "bg-cyan-600",
        textClassName: "text-black"
    },
    {
        name: "Sky",
        color: "#0284c7",
        textColor: "#000",
        className: "bg-sky-600",
        textClassName: "text-black"
    },
    {
        name: "Blue",
        color: "#2563eb",
        textColor: "#000",
        className: "bg-blue-600",
        textClassName: "text-black"
    },
    {
        name: "Indigo",
        color: "#4f46e5",
        textColor: "#fff",
        className: "bg-indigo-600",
        textClassName: "text-white"
    },
    {
        name: "Violet",
        color: "#7c3aed",
        textColor: "#fff",
        className: "bg-violet-600",
        textClassName: "text-white"
    },
    {
        name: "Purple",
        color: "#9333ea",
        textColor: "#000",
        className: "bg-purple-600",
        textClassName: "text-black"
    },
    {
        name: "Fuchsia",
        color: "#c026d3",
        textColor: "#000",
        className: "bg-fuchsia-600",
        textClassName: "text-black"
    },
    {
        name: "Pink",
        color: "#db2777",
        textColor: "#000",
        className: "bg-pink-600",
        textClassName: "text-black"
    },
    {
        name: "Rose",
        color: "#e11d48",
        textColor: "#fff",
        className: "bg-rose-600",
        textClassName: "text-white"
    },
];

export const ColorPicker = ({ colorId, className, buttonText, onChange }: { colorId?: string, className?: string, buttonText?: string, onChange?: (color: ColorPickerColor) => void }) => {
    const [isOpen, setIsOpen] = useState(false);

    const [selectedColor, setSelectedColor] = useState(getColorId(colorId ?? ""));
    const [popOverColor, setPopOverColor] = useState(selectedColor);

    const handleHover = (hoverColor: string | undefined) => {
        if (hoverColor) {
            setPopOverColor(getColorId(hoverColor));
        } else {
            setPopOverColor(selectedColor);
        }
    }

    const handleClick = (hoverColor: string) => {
        setSelectedColor(getColorId(hoverColor));
        setIsOpen(false);
        if (onChange) {
            onChange(getColorId(hoverColor));
        }
    }

    const rows = 3;
    const popOverWidth = (((StandardColors.length / rows) * (1.5 + 0.5)) - 0.25) + (0.75 * 2);
    return (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger className={className}>
                <Button variant="outline" className="w-full text-left flex items-start justify-start gap-3 px-2 pr-3">
                    <ColorTile colorId={selectedColor.name} />
                    <span>{buttonText ?? selectedColor.name}</span>
                </Button>
            </PopoverTrigger>
            <PopoverContent className={`flex flex-col gap-4 p-3`} style={{ width: popOverWidth + "rem" }}>
                <div className={`w-full text-center text-md p-2 font-bold ${popOverColor.className} transition-all select-none rounded-md`}>
                    {popOverColor.name}
                </div>
                <div className="flex flex-wrap gap-2">
                    {StandardColors.map(colorChip => {
                        return <ColorTile
                            colorId={colorChip.name}
                            key={colorChip.name}
                            className="cursor-pointer"
                            onHoverEnter={() => handleHover(colorChip.name)}
                            onHoverLeave={() => handleHover(undefined)}
                            onClick={() => handleClick(colorChip.name)}
                        />
                    })}
                </div>
            </PopoverContent>
        </Popover>
    )
}

const ColorTile = ({ colorId, className = "", showText = false, onHoverEnter, onHoverLeave, onClick }: { colorId: string, className?: string, onHoverEnter?: () => void, onHoverLeave?: () => void, onClick?: () => void, showText?: boolean }) => {
    const color = getColorId(colorId);
    return (
        <div
            className={[
                "w-6",
                "h-6",
                "flex",
                "font-bold",
                "text-sm",
                "select-none",
                "items-center",
                "justify-center",
                "rounded-sm",
                "transition-all",
                color.className,
                color.textClassName,
                className
            ].join(" ")}
            onMouseEnter={onHoverEnter}
            onMouseLeave={onHoverLeave}
            onClick={onClick}
        >{showText ? "Aa" : ""}</div>
    )
}

export const defaultColorPickerColor: ColorPickerColor = StandardColors[0];

const getColorId = (color: string): ColorPickerColor => {
    return StandardColors.find(c => c.name.toLowerCase() === color.toLowerCase()) ?? StandardColors[0];
}