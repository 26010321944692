import { Stonk } from "@/objects/Stonk";
import { Card, CardContent } from "../../ui/card";
import { StonkBrowserItem } from "./StonkBrowserItem";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";
import { Button } from "../../ui/button";
import { useState } from "react";
import { StonkFilterDropdown } from "./StonkFilterDropdown";

export interface StonkFilter {
    id: string;
    name: string;
    getStonks: (stonks: Stonk[]) => Stonk[];
}

export const stonkFilters: StonkFilter[] = [
    {
        id: "owned",
        name: "Owned",
        getStonks: (stonks: Stonk[]) => stonks.filter((stonk: Stonk) => stonk.owned > 0)
    }
];

export const StonkBrowser = ({ onSelectionChange }: { onSelectionChange?: (stonks: Stonk[]) => void }) => {

    const [allStonks, setAllStonks] = useState<Stonk[]>(Array.from(Stonk.all.values()));
    const [selectedStonks, setSelectedStonks] = useState<Stonk[]>([]);
    const [filter, setFilter] = useState<StonkFilter>();

    const onSelectionChangeCallback = (stonks: Stonk[]) => {
        setSelectedStonks(stonks);
        if (onSelectionChange) {
            onSelectionChange(stonks);
        }
    }

    const onFilterChange = (filterId: string | undefined) => {
        const currentFilter = stonkFilters.find((stonkFilter: StonkFilter) => stonkFilter.id === filterId);
        setFilter(currentFilter);
        if (filterId === undefined) {
            setAllStonks(Array.from(Stonk.all.values()));
        } else {
            const newAllStonks = currentFilter!.getStonks(Array.from(Stonk.all.values()));
            setAllStonks(newAllStonks);
            const newSelectedStonks: Stonk[] = [];
            for (const stonk of newAllStonks) {
                if (selectedStonks.includes(stonk)) {
                    newSelectedStonks.push(stonk);
                }
            }
            setSelectedStonks(newSelectedStonks);
            if (onSelectionChange) {
                onSelectionChange(newSelectedStonks);
            }
        }
    }

    return (
        <div className="basis-1/4 flex flex-auto flex-col gap-6">
            <Card className="w-full h-16">
                <CardContent className="p-3 flex gap-3 h-full w-full">
                    <Button className="flex-1" disabled={selectedStonks.length === 0 ? true : false} variant={"outline"} onClick={() => onSelectionChangeCallback([])}>Clear Selection {selectedStonks.length > 0 ? `(${selectedStonks.length})` : ""}</Button>
                    <StonkFilterDropdown stonkFilters={stonkFilters} onSelectionChange={onFilterChange} />
                </CardContent>
            </Card>
            <Card className="w-full flex-1 overflow-y-hidden" fadedEdges={true}>
                <CardContent className="p-0 flex flex-col h-full w-full">
                    {allStonks.map((stonk: Stonk) =>
                        <StonkBrowserItem
                            key={stonk.ticker}
                            stonk={stonk}
                            selected={selectedStonks.includes(stonk)}
                            onClick={(stonk: Stonk) => onSelectionChangeCallback(selectedStonks.includes(stonk) ? selectedStonks.filter((s: Stonk) => s.ticker !== stonk.ticker) : [...selectedStonks, stonk])}
                        />
                    )}
                </CardContent>
            </Card>
        </div>
    );
}