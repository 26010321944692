import BigNumber from "bignumber.js";
import { NumericalFormatOptions, NumericalRecord, SerializedNumericalRecord } from "./NumericalRecord";
import { NumericalEffector } from "./NumericalEffector";
import { GameController } from "./GameController";
import { ConstructorFactory } from "./Serializable";

export interface SerializedPurchasable extends SerializedNumericalRecord {
    targetRecordId: string;
    iteratorValue: string;
    costScalingFactor: string;
    allTimeGeneratedValue: string;
}

export class Purchasable extends NumericalRecord implements NumericalEffector {

    public static allPurchasable: Map<string, Purchasable> = new Map();
    public targetRecord: NumericalRecord = NumericalRecord.get('money', 0);
    public cost: BigNumber = new BigNumber(0);
    public iteratorValue: BigNumber = new BigNumber(0);
    public costScalingFactor: BigNumber = new BigNumber(1.1);
    public allTimeGeneratedValue: BigNumber = new BigNumber(0);
    public maxValue: BigNumber = new BigNumber(10);
    public typeType: ThisType<any> = Purchasable;

    constructor(id: string, value: string | number | BigNumber = 0, formatOptions?: NumericalFormatOptions) {
        super(id, value, formatOptions);
        this.setTargetRecord(this.targetRecord.id)
        NumericalRecord.all.set(id, this as Purchasable);
        Purchasable.allPurchasable.set(id, this);
        this.inheritanceTree.push(super.constructor.name);
    }

    public setTargetRecord(targetRecord: string): void {
        NumericalRecord.addEffector(targetRecord, this);
    }

    public getTargetRecord(): NumericalRecord {
        return NumericalRecord.all.get(this.targetRecord.id);
    }

    public effectorIterator(input: BigNumber) {
        const tickWage = this.iteratorValue
            .dividedBy(60)                      //I per minute
            .dividedBy(GameController.tickRate) //I per tick
            .multipliedBy(this.value);          //I per minute * hours worked
        this.allTimeGeneratedValue = this.allTimeGeneratedValue.plus(tickWage);
        return input.plus(tickWage);
    }

    public isVisible(): boolean {
        return true;
    }

    isDisabled(): string | undefined {
        return undefined;
    }

    public canPurchase(value: BigNumber = new BigNumber(1)): boolean {
        return this.getTargetRecord().value.gte(this.buyPrice()) && (this.value.plus(value).lte(this.maxValue) || this.maxValue.isEqualTo(-1));
    }

    public cantPurchaseReason(value: BigNumber = new BigNumber(1)): string | undefined {
        if (this.value.plus(value).gt(this.maxValue)) {
            return "Max value reached";
        } else if (this.getTargetRecord().value.lt(this.buyPrice())) {
            return "Cannot Afford!";
        }
    }

    public purchase(): void {
        if (this.canPurchase()) {
            this.getTargetRecord().decrement(this.buyPrice())
            this.increment();
        }
    }

    public canSell(value: BigNumber = new BigNumber(1)): boolean {
        return this.value.gt(0);
    }

    public sellPrice(): BigNumber {
        return this.cost.multipliedBy(this.costScalingFactor.pow(this.value.minus(1)));
    }

    public buyPrice(): BigNumber {
        return this.cost.multipliedBy(this.costScalingFactor.pow(this.value));
    }

    public sell(): void {
        if (this.value.gt(0)) {
            this.getTargetRecord().increment(this.sellPrice())
            this.decrement();
        }
    }

    public serialize(): SerializedPurchasable {
        return {
            ...super.serialize(),
            targetRecordId: this.targetRecord.id,
            iteratorValue: this.iteratorValue.toString(),
            costScalingFactor: this.costScalingFactor.toString(),
            allTimeGeneratedValue: this.allTimeGeneratedValue.toString(),
        }
    }

    public static deserialize(data: SerializedPurchasable, constructor?: ConstructorFactory): any {
        if (!constructor) {
            constructor = Purchasable;
        }
        const record = new constructor(data.id, data.value, data.formatOptions ? data.formatOptions : {});
        record.strAllTimeValue = data.allTimeValue;
        record.allTimeValue = new BigNumber(data.allTimeValue);
        record.datapoints = data.datapoints;
        record.targetRecord = NumericalRecord.get(data.targetRecordId, 0);
        record.iteratorValue = new BigNumber(data.iteratorValue);
        record.costScalingFactor = new BigNumber(data.costScalingFactor);
        record.allTimeGeneratedValue = new BigNumber(data.allTimeGeneratedValue);
        record.inheritanceTree = data.type.inheritanceTree;
        return record;
    }

}