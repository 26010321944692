import { useState } from "react";
import {
    NavigationMenu,
    NavigationMenuItem,
    NavigationMenuList,
} from "@/components/ui/navigation-menu";
import './NavBar.css'

import { GitHubLogoIcon } from "@radix-ui/react-icons";
import { ChartLine, Menu } from "lucide-react";
import { buttonVariants } from "../ui/button";
import { ModeToggle } from "./ModeToggle";

import packageInfo from "../../../package.json";
import { UserMenu } from "./UserMenu";
import { NavLink } from "react-router-dom";
import { NumericalRecordWidget } from "../NumericalRecordWidget";
import { NumericalRecord } from "@/objects/NumericalRecord";
import { Badge } from "../ui/badge";
import { GameController } from "@/objects/GameController";
import { useGameMetadata } from "@/objects/useGameMetadata";

interface RouteProps {
    href: string;
    label: string;
    enabled?: boolean;
    devModeOnly?: boolean;
}

const routeList: RouteProps[] = [
    {
        href: "",
        label: "Home",
        enabled: true,
    },
    {
        href: "jobs",
        label: "Jobs",
        enabled: true,
    },
    {
        href: "shop",
        label: "Shop",
        enabled: false,
    },
    {
        href: "stonks",
        label: "Stonk Market",
        enabled: true,
    },
    {
        href: "test",
        label: "Test Page",
        enabled: true,
        devModeOnly: true,
    }
];

export const NavBar = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { metadata } = useGameMetadata();
    return (
        <header className="sticky border-b-[1px] h-14 top-0 z-40 w-full bg-white/50 dark:border-b-slate-700/25 dark:bg-background/50 backdrop-blur-lg">
            <NavigationMenu>
                <NavigationMenuList className="h-14 px-6 w-screen flex justify-between ">
                    <NavigationMenuItem className="font-bold flex nav-title">
                        <a
                            rel="noreferrer noopener"
                            href="/"
                            className="ml-2 font-bold text-xl flex"
                        >
                            <ChartLine />
                            Stonks
                        </a>


                        {/* desktop */}
                        <nav className="hidden md:flex gap-8">
                            <NumericalRecordWidget recordId="money" />
                            <div className="flex gap-2">
                                {routeList.map((route: RouteProps, i) => (
                                    (route.devModeOnly && !GameController.devMode) ?
                                        <></> :
                                        (route.enabled ?
                                            <NavLink
                                                to={route.href}
                                                className={({ isActive, isPending }) => {
                                                    return `text-[17px] ${buttonVariants({
                                                        variant: (isActive || isPending ? "secondary" : "ghost"),
                                                    })}`
                                                }}
                                                key={i}
                                            >
                                                {route.label}
                                            </NavLink> :
                                            <div key={i} className={`text-[17px] select-none cursor-pointer opacity-50 ${buttonVariants({
                                                variant: "ghost",
                                            })}`}>{route.label}</div>)
                                ))}
                            </div>
                        </nav>
                    </NavigationMenuItem>
                    <NavigationMenuItem className="flex items-center gap-x-2">
                        {metadata.isDeveloper ? <Badge variant="destructive">Developer Mode</Badge> : null}
                        <div>
                            <span className="text-sm text-muted">{packageInfo.version}</span>
                        </div>
                        <UserMenu />
                    </NavigationMenuItem>
                </NavigationMenuList>
            </NavigationMenu>
        </header >
    );
};