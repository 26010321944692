import BigNumber from "bignumber.js";
import { NumericalEffector } from "./NumericalEffector";
import { GameController } from "./GameController";
import { PollingManager } from "./PollingManager";
import { Serializable, SerializedRecord } from "./Serializable";
import { Type } from "typescript";
import { ClassType } from "react";

export interface SerializedNumericalRecord extends SerializedRecord {
    value: string;
    allTimeValue: string;
    datapoints: NumericalRecordDataPoint[];
    formatOptions?: NumericalFormatOptions;
}

export interface NumericalRecordDataPoint {
    value: string;
    time: number;
}

export interface NumericalFormatOptions {
    name?: string;
    description?: string;
    unitName?: string;
}

export interface NumericalRecordTarget {
    targetRecord: NumericalRecord;
}

export type NumericalRecordHookCallback = (value: string) => void;

export interface NumericalRecordHook {
    callback: NumericalRecordHookCallback;
    minimumInterval: number;
    lastCalled: number;
}

export class NumericalRecord implements Serializable {
    public static all: Map<string, any> = new Map();
    public static effectors: Map<string, NumericalEffector[]> = new Map();

    public hooks: NumericalRecordHook[] = [];
    private hookPollingManager: PollingManager = new PollingManager(GameController.uiTickRate);

    public inheritanceTree: string[] = [];
    public id: string;
    public value: BigNumber;
    public strValue: string;
    public allTimeValue: BigNumber;
    public strAllTimeValue: string;
    public typeType: ThisType<any> = NumericalRecord;

    public datapoints: NumericalRecordDataPoint[] = [];
    private static maxDatapoints: number = 1000;
    private datapointPollingManager: PollingManager = new PollingManager(GameController.dataLoggingTickRate);

    private deltaPerTick: BigNumber = new BigNumber(0);
    private valueUpdated: boolean = false;
    public formatOptions: NumericalFormatOptions;

    constructor(id: string, value: string | number | BigNumber = 0, formatOptions?: NumericalFormatOptions) {
        this.id = id;
        this.formatOptions = formatOptions ?? {};
        this.value = typeof value === "string" || typeof value === "number" ? new BigNumber(value) : value;
        this.strValue = this.value.toString();
        this.allTimeValue = new BigNumber(this.strValue);
        this.strAllTimeValue = this.strValue;
        NumericalRecord.all.set(id, this);
    }

    public static get(id: string, value: string | number | BigNumber = 0, formatOptions?: NumericalFormatOptions): NumericalRecord {
        if (NumericalRecord.all.has(id)) {
            const existing = NumericalRecord.all.get(id)!;
            if (formatOptions) {
                existing.formatOptions = formatOptions;
            }
            return existing;
        }
        return new NumericalRecord(id, value, formatOptions);
    }

    public updateValue(value: string | number | BigNumber): void {
        if (typeof value === "string" || typeof value === "number") {
            value = new BigNumber(value);
        }
        this.value = value;
        this.strValue = this.value.toString();
        if (this.value.isGreaterThan(this.allTimeValue)) {
            this.allTimeValue = this.value;
            this.strAllTimeValue = this.allTimeValue.toString();
        }
        if (!this.deltaPerTick.isZero() || this.valueUpdated) {
            this.hookPollingManager.attemptRun(() => {
                this.hooks.forEach((hook) => {
                    if (hook.lastCalled + hook.minimumInterval < Date.now()) {
                        hook.callback(this.strValue)
                        hook.lastCalled = Date.now();
                    }
                });
            });
            this.valueUpdated = false;
        }
    }

    public static updateAllValues(): void {
        for (const record of NumericalRecord.all.values()) {
            record.updateValue(record.value);
        }
    }

    public increment(i: string | number | BigNumber = 1): void {
        if (typeof i === "string" || typeof i === "number") {
            i = new BigNumber(i);
        }
        const newVal = this.value.plus(i);
        this.updateValue(newVal);
        this.valueUpdated = true;
    }

    public decrement(i: string | number | BigNumber = 1): void {
        if (typeof i === "string" || typeof i === "number") {
            i = new BigNumber(i);
        }
        const newVal = this.value.minus(i);
        this.updateValue(newVal);
        this.valueUpdated = true;
    }

    public addHook(hook: NumericalRecordHookCallback, minimumInterval: number = 1000 / GameController.uiTickRate): void {
        this.hooks.push({
            callback: hook,
            minimumInterval: minimumInterval,
            lastCalled: 0
        });
    }

    public static addEffector(id: string, effector: NumericalEffector): void {
        if (!NumericalRecord.effectors.has(id)) {
            NumericalRecord.effectors.set(id, []);
        }
        if (!NumericalRecord.effectors.get(id)!.includes(effector)) {
            NumericalRecord.effectors.get(id)!.push(effector);
        }
    }

    public processEvents(): void {
        const previousValue = new BigNumber(this.value.toString());
        let newValue = new BigNumber(this.value.toString());
        if (NumericalRecord.effectors.has(this.id)) {
            for (const effector of NumericalRecord.effectors.get(this.id)!) {
                newValue = effector.effectorIterator(newValue);
                if (newValue.isGreaterThan(this.allTimeValue)) {
                    this.allTimeValue = newValue;
                }
            }
        }
        this.strAllTimeValue = this.allTimeValue.toString();
        this.deltaPerTick = newValue.minus(previousValue);
        this.updateValue(newValue);


        this.datapointPollingManager.attemptRun(() => {
            this.datapoints.push({
                value: this.strValue,
                time: Date.now()
            });
            if (this.datapoints.length > NumericalRecord.maxDatapoints) {
                this.datapoints.shift();
            }
        });
    }

    public getDelta(interval: "tick" | "second" | "minute" | "hour" = "tick"): BigNumber {

        switch (interval) {
            case "tick":
                return this.deltaPerTick;
            case "second":
                return this.deltaPerTick.multipliedBy(GameController.tickRate);
            case "minute":
                return this.deltaPerTick.multipliedBy(GameController.tickRate).multipliedBy(60);
            case "hour":
                return this.deltaPerTick.multipliedBy(GameController.tickRate).multipliedBy(60).multipliedBy(60);
            default:
                return this.deltaPerTick;
        }
    }

    public toString(): string {
        return this.value.toString();
    }

    serialize(): SerializedNumericalRecord {
        this.strAllTimeValue = this.allTimeValue.toString();
        this.strValue = this.value.toString();
        const inheritanceTree = [...this.inheritanceTree];
        if (!inheritanceTree.includes(this.constructor.name)) {
            inheritanceTree.push(this.constructor.name);
        }
        return {
            id: this.id,
            value: this.strValue,
            allTimeValue: this.strAllTimeValue,
            datapoints: this.datapoints,
            type: {
                inheritanceTree: inheritanceTree
            },
            ...(Object.keys(this.formatOptions).length > 0 ? { formatOptions: this.formatOptions } : {})
        };
    }

    static deserialize(data: SerializedNumericalRecord): NumericalRecord {
        const record = new NumericalRecord(data.id, data.value, data.formatOptions ? data.formatOptions : {});
        record.strAllTimeValue = data.allTimeValue;
        record.allTimeValue = new BigNumber(data.allTimeValue);
        record.datapoints = data.datapoints;
        record.inheritanceTree = data.type.inheritanceTree;
        return record;
    }
}