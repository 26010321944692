import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../ui/accordion";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../ui/dialog";
import licenses from "../../generated/licenses.json";
import { Input } from "../ui/input";
import { useEffect, useRef, useState } from "react";
import { ViewportList } from "react-viewport-list";
import Fuse from "fuse.js";

const options = {
    includeScore: true,
    // Search in `author` and in `tags` array
    keys: ['packageName']
}

const fuse = new Fuse(licenses, options);

export const OpenSourceDialog = ({ isOpen, onOpenChange }: { isOpen: boolean, onOpenChange?: (state: boolean) => void }) => {

    const [search, setSearch] = useState("");
    const [isStateOpen, setIsStateOpen] = useState(isOpen);

    useEffect(() => {
        setIsStateOpen(isOpen);
    }, [isOpen]);

    const onInternalOpenChange = (state: boolean) => {
        if (onOpenChange) {
            onOpenChange(state);
        }
    }

    const result = fuse.search(search).map((result) => result.item);

    const ref = useRef<HTMLDivElement | null>(
        null,
    );

    return (
        <Dialog open={isStateOpen} onOpenChange={onInternalOpenChange}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Open Source Licenses</DialogTitle>
                    <DialogDescription className="flex flex-col gap-4">
                        Stonks.sh was not made alone. Check out some of the open source projects we used when building the game.
                        <Input type="text" placeholder="Search..." className="w-full" onChange={(e) => setSearch(e.target.value)} />
                        <div className="max-h-80 overflow-y-scroll" ref={ref}>
                            <Accordion type="single" collapsible className="w-full">
                                <ViewportList
                                    viewportRef={ref}
                                    items={search.length === 0 ? licenses : result}
                                >
                                    {(item, index) => (
                                        <OpenSourcePackageLicense
                                            license={item}
                                            key={index}
                                            show={true}
                                        />
                                    )}
                                </ViewportList>
                            </Accordion>
                        </div>
                    </DialogDescription>
                </DialogHeader>
            </DialogContent>
        </Dialog>
    );
};

const OpenSourcePackageLicense = ({ license, show }: { license: typeof licenses[0], show: boolean }) => {
    return (
        <AccordionItem value={license.packageName} className={show ? "" : "hidden"} >
            <AccordionTrigger>{license.packageName} {license.packageVersion}</AccordionTrigger>
            <AccordionContent>
                <div className="p-2 flex flex-col gap-4">
                    <span>
                        <span className="font-bold">Author: </span>
                        {license.publisher} {license.email ? <>&lt;{license.email}&gt;</> : null}
                    </span>
                    <span>
                        <span className="font-bold">Repository: </span>
                        <a href={license.repository} target="_blank" className="text-blue-700 underline">{license.repository}</a>
                    </span>
                    <div className="font-mono whitespace-pre-line">
                        {license.licenseText}
                    </div>
                </div>
            </AccordionContent>
        </AccordionItem>
    )
};