import prand from "pure-rand";
import { Stonk } from "../Stonk";
import { inflationStonkLayer, marketFluctuationStonkLayer, noiseStonkLayer, StonkLayer, StonkLayerAffector } from "./StonkLayer";
import associations from "../../generated/associations.json";
export interface SerializedStonkIndustry {
    industryName: string;
    startingTime: number;
    rootSeed: number;
}

export class StonkIndustry {
    public static all: Map<string, StonkIndustry> = new Map();
    public static isInitialized: boolean = false;
    public static globalLayers: StonkLayer[] = [marketFluctuationStonkLayer, noiseStonkLayer, inflationStonkLayer];

    public industryName: string;
    public startingTime: number;
    public rootSeed: number;
    private generator: prand.RandomGenerator;

    private constructor(name: string, options?: SerializedStonkIndustry) {
        this.industryName = name;
        this.rootSeed = options?.rootSeed ?? Math.random() * (Date.now() / (1000 * 60 * 60 * 24));
        this.generator = prand.xoroshiro128plus(this.rootSeed);
        this.startingTime = options?.startingTime ?? Date.now();
        StonkIndustry.all.set(name, this);
    }

    public calculateNewValue(stonk: Stonk, value: number, time: number): number {
        let influence = 0;
        for (const layer of StonkIndustry.globalLayers) {
            influence = layer(this, influence, time, 2);
        }
        const industryInfluence = stonk.associations[this.industryName as keyof typeof associations.companyTypes.Software] ?? 0;
        return value + ((value / 4) * influence * industryInfluence);
    }

    public static addIndustryInfluence(stonk: Stonk, value: number, time: number): number {
        if (!StonkIndustry.isInitialized) {
            StonkIndustry.initialize();
        }
        for (const industry of StonkIndustry.all.values()) {
            value = industry.calculateNewValue(stonk, value, time);
        }
        return value;
    }

    public static initialize() {
        const industryNames = Object.keys(associations.companyTypes.Software);
        for (const industryName of industryNames) {
            const industry = new StonkIndustry(industryName);
            StonkIndustry.all.set(industryName, industry);
        }
        StonkIndustry.isInitialized = true;
    }

    public serialize(): SerializedStonkIndustry {
        return {
            industryName: this.industryName,
            startingTime: this.startingTime,
            rootSeed: this.rootSeed
        };
    }

    public static deserialize(data: SerializedStonkIndustry) {
        const industry = new StonkIndustry(data.industryName, data);
        StonkIndustry.all.set(data.industryName, industry);
        StonkIndustry.isInitialized = true;
    }

    public static clear() {
        StonkIndustry.all.clear();
        StonkIndustry.isInitialized = false;
    }
}