import { SquarePen } from "lucide-react"
import { Avatar, AvatarFallback, AvatarImage } from "./avatar"
import { ColorPickerColor, defaultColorPickerColor } from "./colorPicker";
import { getIconId, IconType } from "./iconPicker";
import React, { ReactElement, useEffect, useState } from "react";

export type AvatarType = "initials" | "icon" | "image";

export interface AvatarProps {
    type: AvatarType;
    color: ColorPickerColor;
    imgData?: string;
    initials?: string;
    iconId?: string;
}

export const defaultAvatarProps: AvatarProps = {
    type: "icon",
    iconId: "User",
    color: defaultColorPickerColor
};

export const AvatarPicker = ({ className, props, onChange, canEdit = true }: { className?: string, props?: AvatarProps | undefined, onChange?: (avatar: string) => void, canEdit?: boolean }) => {
    const [avatarProps, setAvatarProps] = useState<AvatarProps>({ ...defaultAvatarProps, ...(props ?? {}) });
    const color = avatarProps.color ?? defaultColorPickerColor;
    const Icon = getIconId(avatarProps.iconId ?? "User").icon;

    const avatarImg = avatarProps.type === "image" ? avatarProps.imgData : undefined;

    useEffect(() => {
        setAvatarProps({ ...defaultAvatarProps, ...(props ?? {}) });
    }, [props]);

    const handleClick = async () => {
        if (canEdit) {
            const data = await PromptUpload();
            setAvatarProps((originalProps: AvatarProps) => {
                return { ...originalProps, type: "image", imgData: data }
            });
            if (onChange) {
                onChange(data);
            }
        }
    };

    return (
        <Avatar className={className} onClick={handleClick}>
            <AvatarImage className={avatarImg === undefined ? "opacity-0" : "opacity-100"} src={avatarImg} />
            <AvatarFallback className={`${color.className} ${color.textClassName} transition-all`}>
                {avatarProps.type === "initials" ?
                    <svg viewBox="0 0 30 30">
                        <text x="15" y="20" text-anchor="middle" className="font-sans font-bold fill-current tracking-tight transition-fill select-none">{avatarProps.initials ?? ""}</text>
                    </svg>
                    :
                    React.cloneElement(Icon as ReactElement, { size: "60%", className: "text-white" })
                }
            </AvatarFallback>
            <div className={`absolute w-full h-full bg-black/50 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity select-none cursor-pointer ${canEdit ? "" : "hidden"}`}>
                <SquarePen size="40%" className="text-white" />
            </div>
        </Avatar>
    )
}

const PromptUpload = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
        var input = document.createElement('input');
        input.type = 'file';
        input.accept = "image/*";
        input.click();
        input.onchange = function () {
            if (!input.files || input.files.length === 0) {
                reject();
                return;
            }
            var file = input.files[0];
            var reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async () => {
                var arrayBuffer = reader.result;
                var blob = new Blob([arrayBuffer as ArrayBuffer], {
                    type: "image/*"
                });
                resolve(await blobToBase64(blob));
            };
        };
    });
}

const blobToBase64 = async (blob: Blob): Promise<string> => {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result?.toString() ?? "");
        reader.readAsDataURL(blob);
    });
}