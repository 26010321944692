import { ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { GameController } from "@/objects/GameController";
import { NumericalRecord, NumericalRecordDataPoint } from "@/objects/NumericalRecord";
import { useNumericalRecord } from "@/objects/useNumericalRecord";
import { useEffect, useState } from "react";
import { Area, AreaChart, XAxis, YAxis } from "recharts";

export function NumericalRecordCardChart({ record }: { record: NumericalRecord }) {
    const { value, delta } = useNumericalRecord(record, GameController.dataLoggingTickRate);
    const [rawData, setRawData] = useState<NumericalRecordDataPoint[]>([]);

    useEffect(() => {
        setRawData(record.datapoints);
    }, [value]);

    const data = rawData.map((datapoint) => {
        return {
            time: datapoint.time,
            value: parseFloat(datapoint.value),
        };
    });

    return (
        <ChartContainer
            config={{
                time: {
                    label: "Time",
                    color: "hsl(var(--chart-2))",
                },
            }}
            className="w-full"
        >
            <AreaChart
                accessibilityLayer
                data={data}
                margin={{
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                }}
            >
                <XAxis dataKey="time" hide />
                <YAxis domain={["dataMin - 5", "dataMax + 2"]} hide />
                <defs>
                    <linearGradient id="fillTime" x1="0" y1="0" x2="0" y2="1">
                        <stop
                            offset="5%"
                            stopColor="var(--color-time)"
                            stopOpacity={0.8}
                        />
                        <stop
                            offset="95%"
                            stopColor="var(--color-time)"
                            stopOpacity={0.1}
                        />
                    </linearGradient>
                </defs>
                <Area
                    dataKey="value"
                    type="natural"
                    fill="url(#fillTime)"
                    fillOpacity={0.4}
                    stroke="var(--color-time)"
                />
                <ChartTooltip
                    cursor={false}
                    content={<ChartTooltipContent hideLabel />}
                    formatter={(value: number) => (
                        <div className="flex min-w-[120px] items-center text-xs text-muted-foreground">
                            Account Balance&nbsp;$
                            <div className="ml-auto flex items-baseline gap-0.5 font-mono font-medium tabular-nums text-foreground">
                                {value.toFixed(2)}
                            </div>
                        </div>
                    )}
                />
            </AreaChart>
        </ChartContainer>
    );
}