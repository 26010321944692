import { CircleDollarSign, Code } from "lucide-react";
import { DropdownMenuItem, DropdownMenuPortal, DropdownMenuSub, DropdownMenuSubContent, DropdownMenuSubTrigger } from "../../ui/dropdown-menu";
import { BuildSelectorMenu } from "./BuildSelector";
import { NumericalRecord } from "@/objects/NumericalRecord";


export function InternalTools() {
    return (
        <>
            <DropdownMenuSub>
                <DropdownMenuSubTrigger>
                    <Code className="mr-2 h-4 w-4" />
                    <span>Build Selector</span>
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                    <DropdownMenuSubContent>
                        <BuildSelectorMenu />
                    </DropdownMenuSubContent>
                </DropdownMenuPortal>
            </DropdownMenuSub>
            <DropdownMenuItem onClick={() => NumericalRecord.get('money').increment(10000)}>
                <CircleDollarSign className="mr-2 h-4 w-4" />
                <span>Cheat Money</span>
            </DropdownMenuItem>
        </>
    )
}