import * as React from "react"
import './card.css'

import { cn } from "@/lib/utils"
import { useState } from "react";

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { fadedEdges?: boolean }
>(({ className, ...props }, ref) => {
  const { fadedEdges, children, ...otherProps } = props;
  let content = children;
  const [topEdge, setTopEdge] = useState(false);
  const [bottomEdge, setBottomEdge] = useState(true);
  if (fadedEdges) {
    const scrollListener = (e: any) => {
      if (e.target.scrollTop < 20) {
        setTopEdge(false);
      } else {
        setTopEdge(true);
      }
      if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 20) {
        setBottomEdge(false);
      } else {
        setBottomEdge(true);
      }
    }
    content = (
      <div className="flex relative flex-col h-full w-full">
        <div className={"h-8 min-h-8 w-full absolute top-0 bg-gradient-to-b from-card to-transparent pointer-events-none transition-opacity duration-250 " + (topEdge ? "" : "opacity-0")} />
        <div className="h-full w-full overflow-y-scroll no-scrollbar" onScroll={scrollListener}>
          {children}
        </div>
        <div className={"h-8 min-h-8 w-full absolute bottom-0 w-full bg-gradient-to-t from-card to-transparent pointer-events-none transition-opacity duration-250 " + (bottomEdge ? "" : "opacity-0")} />
      </div>
    );
  }
  return (
    <div
      ref={ref}
      className={cn(
        "rounded-lg border bg-card text-card-foreground shadow-sm",
        className
      )}
      {...otherProps}
    >
      {content}
    </div>
  );
})
Card.displayName = "Card"

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-1.5 p-6", className)}
    {...props}
  />
))
CardHeader.displayName = "CardHeader"

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      "text-2xl font-semibold leading-none tracking-tight",
      className
    )}
    {...props}
  />
))
CardTitle.displayName = "CardTitle"

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
))
CardDescription.displayName = "CardDescription"

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("p-6 pt-0", className)} {...props} />
))
CardContent.displayName = "CardContent"

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center p-6 pt-0", className)}
    {...props}
  />
))
CardFooter.displayName = "CardFooter"

export { Card, CardHeader, CardFooter, CardTitle, CardDescription, CardContent }
