import { useEffect, useState } from "react";
import { NumericalRecord } from "./NumericalRecord";
import { GameController } from "./GameController";
import { Stonk } from "./Stonk";

export function useStonk(stonk: Stonk, deltaInterval: number, skip: boolean = false, minimumInterval: number = 1000 / GameController.uiTickRate) {
    const [value, setValue] = useState<number>(skip ? 0 : stonk.currentValue());
    const [delta, setDelta] = useState<number>(skip ? 0 : stonk.delta(deltaInterval));

    useEffect(() => {
        const updateHook = {
            id: (stonk ? stonk.ticker : "unknown-stonk") + "-useStonk-hook",
            hook: () => {
                setValue(stonk.currentValue());
                setDelta(stonk.delta(deltaInterval));
            },
            interval: minimumInterval
        }

        if (!skip) {
            GameController.removeGlobalHook(updateHook);
            GameController.addGlobalHook(updateHook);
        } else {
            GameController.removeGlobalHook(updateHook);
        }
    }, [minimumInterval, skip]);

    return {
        value,
        delta
    };
}